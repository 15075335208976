import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Checkbox } from '@atlaskit/checkbox'
import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import { GetDispatchPayoutDetailsQuery, SubmitInstallerPayoutValueMutationVariables } from '@/gql/graphql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useGraphQLClient from '@/utils/useAuthRequest'
import { CardColorOptions, COLOR_OPTIONS } from '@/utils/utilities'

import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

type WorkOrder = GetDispatchPayoutDetailsQuery['workOrder']
type Dispatch = WorkOrder['dispatches'][number]
type InstallerAssignments = Dispatch['installerAssignments'][number]

const CELL_PAD = '4px 8px'

interface InstallerProperties {
  percentPieceRate?: string
  hoursWorked?: string
  isLocked?: boolean
  isChanged?: boolean
  hourlyRate?: string
}

interface InstallerRowProps {
  installerDispatch: InstallerAssignments
  isLastOfVehicle: boolean
  isFirstOfVehicle: boolean
}

const SuffixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.subtlest')};
  margin-right: 8px;
  font-family: ${MONOSPACE_FONT_FAMILY};
`

const InstallerRow = ({ installerDispatch, isLastOfVehicle = false, isFirstOfVehicle = true }: InstallerRowProps) => {
  const [installerProps, setInstallerAmounts] = useState<InstallerProperties>({})

  const installerId = installerDispatch.installerId as string
  const installerDispatchId = installerDispatch.id

  const {
    vehicleName,
    isHourly,
    driveHoursPayable,
    driveHourlyPayRate,
    payoutDrive,
    payoutHourly,
    payoutPieceRate,
    payoutAddons,
    payoutTotal,
  } = installerDispatch

  useEffect(() => {
    if (installerProps?.percentPieceRate) {
      if (installerDispatch.percentPieceRate === installerProps.percentPieceRate)
        setInstallerAmounts(prev => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { percentPieceRate: _, ...rest } = prev
          return rest
        })
    }
  }, [installerProps?.percentPieceRate, installerDispatch.percentPieceRate])

  const installerName = installerDispatch.installer.fullName
  const hourlyRate = installerProps?.hourlyRate ?? installerDispatch.hourlyRate
  const hoursWorked = installerProps?.hoursWorked ?? installerDispatch.hoursWorked
  const percentPieceRate = installerProps?.percentPieceRate ?? installerDispatch.percentPieceRate

  const isLocked = installerProps?.isLocked ?? installerDispatch.isPercentLocked

  const vehicleColor = (
    COLOR_OPTIONS.includes((installerDispatch.vehicleColor ?? '') as CardColorOptions)
      ? (installerDispatch.vehicleColor as CardColorOptions)
      : 'gray'
  ) as CardColorOptions
  const vehicleBackground = !vehicleColor ? token('elevation.surface') : token(`color.background.accent.${vehicleColor}.subtlest`)
  const color = token(`color.text.accent.${vehicleColor}`)

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationFn: async (variables: SubmitInstallerPayoutValueMutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_INSTALLER_PAYOUT_VALUE, variables)
      if (!response.submitInstallerPayoutValue?.success)
        throw new Error(response.submitInstallerPayoutValue?.message ?? 'Failed to submit addon')
      return response.submitInstallerPayoutValue
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
    },
  })

  return (
    <tr key={installerDispatch.id}>
      <td style={{ background: vehicleBackground, color, borderBottomColor: isLastOfVehicle ? undefined : vehicleBackground }}>
        {isFirstOfVehicle ? vehicleName : ''}
      </td>

      <td>{installerName}</td>

      <td style={{ textAlign: 'center' }}>{isHourly ? 'Hr' : 'P'}</td>

      <td>
        <TableCellNumberTextField
          isCompact
          value={hoursWorked}
          elemAfterInput={<SuffixText>hrs</SuffixText>}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts(prev => ({ ...prev, hoursWorked: e.target.value }))}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, hoursWorked: value }))
            mutate({ installerDispatchId, attribute: 'hoursWorked', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
        />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          value={hourlyRate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts(prev => ({ ...prev, hourlyRate: e.target.value }))}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, hourlyRate: value }))
            mutate({ installerDispatchId, attribute: 'hourlyRate', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
          suffix='/ hr'
          prefix='$'
        />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={payoutHourly} prefix='$' />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          value={driveHoursPayable}
          elemAfterInput={<SuffixText>hrs</SuffixText>}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInstallerAmounts(prev => ({ ...prev, driveHoursPayable: e.target.value }))
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, driveHoursPayable: value }))
            mutate({ installerDispatchId, attribute: 'driveHoursPayable', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
        />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          value={driveHourlyPayRate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInstallerAmounts(prev => ({ ...prev, [installerId]: { ...installerProps, driveHourlyPayRate: e.target.value } }))
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, driveHourlyPayRate: value }))
            mutate({ installerDispatchId, attribute: 'driveHourlyPayRate', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
          suffix='/ hr'
          prefix='$'
        />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={payoutDrive} prefix='$' />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          isDisabled={isLocked}
          value={percentPieceRate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInstallerAmounts(prev => ({ ...prev, percentPieceRate: e.target.value }))
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            let value: number | string = e.target.value === '' ? Number(0) : Number(e.target.value)
            if (value > 100) value = Number('100.00')
            value = value.toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, percentPieceRate: value }))
            mutate({ installerDispatchId, attribute: 'percentPieceRate', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
          suffix='%'
        />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={payoutAddons} prefix='$' />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={payoutPieceRate} prefix='$' />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={payoutTotal} prefix='$' />
      </td>

      <td className='no-padding'>
        <div className='checkbox-wrapper'>
          <Checkbox isChecked={isLocked} onChange={handleCheckboxChange} />
        </div>
      </td>
    </tr>
  )
  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInstallerAmounts(prev => ({ ...prev, isLocked: e.target.checked }))
    mutate({ installerDispatchId, attribute: e.target.checked ? 'shouldLock' : 'shouldUnlock', value: null })
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      e.target.select()
    }, 1) // Delay until the next event loop cycle
  }

  function handleReturn(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      ;(e.target as HTMLInputElement).blur()
    }
  }
}

export default InstallerRow

const SUBMIT_INSTALLER_PAYOUT_VALUE = graphql(/* GraphQL */ `
  mutation SubmitInstallerPayoutValue($installerDispatchId: ID!, $attribute: String!, $value: String) {
    submitInstallerPayoutValue(installerDispatchId: $installerDispatchId, attribute: $attribute, value: $value) {
      success
      message
    }
  }
`)
