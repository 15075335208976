import { useMutation, useQueryClient } from '@tanstack/react-query'

import { graphql } from '@/gql'
import { GetPayrollDayQuery, SubmitVehicleDateStatsMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

const useSubmitVehicleDateStats = (dateString: string) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate, isPending, data } = useMutation({
    mutationFn: async (variables: SubmitVehicleDateStatsMutationVariables) => {
      const result = await graphQLClient.request(SUBMIT_VEHICLE_DATE_STATS, variables)
      if (!result.submitVehicleDateStats.success) {
        throw new Error(result.submitVehicleDateStats.message)
      }
      return result
    },
    onMutate: async variables => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['GetPayrollDay', dateString] })

      // Snapshot the previous value
      const previousData = queryClient.getQueryData<GetPayrollDayQuery>(['GetPayrollDay', dateString])

      // Optimistically update to the new value
      queryClient.setQueryData<GetPayrollDayQuery>(['GetPayrollDay', dateString], old => {
        if (!old) return old

        return {
          ...old,
          vehiclesByDate: old.vehiclesByDate.map(vehicle => {
            if (!vehicle.vehicleDate || vehicle.vehicleDate.id !== variables.vehicleDateId) {
              return vehicle
            }

            // Handle vehicle date level updates
            if (!variables.vehicleDispatchId) {
              return {
                ...vehicle,
                vehicleDate: {
                  ...vehicle.vehicleDate,
                  [variables.attribute]: variables.value,
                },
              }
            }

            // Handle vehicle dispatch level updates
            return {
              ...vehicle,
              vehicleDate: {
                ...vehicle.vehicleDate,
                vehicleDispatchAssignments: vehicle.vehicleDate.vehicleDispatchAssignments.map(dispatch => {
                  if (dispatch.id !== variables.vehicleDispatchId) {
                    return dispatch
                  }
                  return {
                    ...dispatch,
                    [variables.attribute]: variables.value,
                  }
                }),
              },
            }
          }),
        }
      })

      return { previousData }
    },
    onSuccess: result => {
      // Update the cache with the returned data
      queryClient.setQueryData<GetPayrollDayQuery>(['GetPayrollDay', dateString], old => {
        if (!old) return old

        return {
          ...old,
          vehiclesByDate: old.vehiclesByDate.map(vehicle => {
            if (!vehicle.vehicleDate || vehicle.vehicleDate.id !== result.submitVehicleDateStats?.vehicleDate?.id) {
              return vehicle
            }

            return {
              ...vehicle,
              vehicleDate: result.submitVehicleDateStats?.vehicleDate,
            }
          }),
        }
      })
    },
    onError: (err, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousData) {
        queryClient.setQueryData(['GetPayrollDay', dateString], context.previousData)
      }
    },
  })

  return { mutate, isPending, data }
}

export default useSubmitVehicleDateStats

const SUBMIT_VEHICLE_DATE_STATS = graphql(/* GraphQL */ `
  mutation SubmitVehicleDateStats($vehicleDateId: ID!, $vehicleDispatchId: ID, $attribute: String!, $value: String) {
    submitVehicleDateStats(vehicleDateId: $vehicleDateId, vehicleDispatchId: $vehicleDispatchId, attribute: $attribute, value: $value) {
      success
      message
      vehicleDate {
        id
        isLocked
        sortedVehicleDispatchIds
        totalMilesDriven
        totalMinutesDriven
        totalProximityMinutes
        minutesToArriveHome
        milesToArriveHome
        totalDrivePayout

        vehicleDispatchAssignments {
          id
          vehicleDateId
          proximityPercent
          proximityMinutes
          minutesToArrive
          milesToArrive
          driveHourlyPayRate
          driveHoursPayable
          calculatedDrivePayoutTotal
          calculatedDrivePayoutPerInstaller
          installerCount
          milesAdjusted
          driveMinutesAdjusted
          driveMinutesBuffer
          hoursOnSite
          assignedInstallerIds

          dispatchId
          dispatch {
            id
            date
            totalHoursWorked

            workOrderId
            workOrder {
              id
              name
              tripName
              isConfirmed
              isWorkCompleted
              estimateId
              estimateTitle
              isDavisBacon
              jobId
              jobTitle
              customerName
              projectSiteAddress
            }
          }
        }
      }
    }
  }
`)
