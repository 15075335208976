import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { Route, Routes, useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import Badge from '@atlaskit/badge'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'

import AppPage from '@/components/AppPage'

import { graphql } from '@/gql'
import { JobType, SubmitJobMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import CreateEstimateModal from '../CreateEstimateModal/CreateEstimateModal'
import { SUBMIT_JOB_MUTATION } from '../JobsList/CreateJobForm'
import JobPageActions from './JobPageActions'
import JobPageRightSidebar from './JobPageRightSidebar'
import JobPageTabEstimates from './JobPageTabEstimates'
import JobPageTabNotes from './JobPageTabNotes'

const NotesBadge = ({ notesCount }: { notesCount: number }) => {
  if (notesCount === 0) return null
  return (
    <span style={{ marginLeft: 4 }}>
      <Badge appearance='primary'>{notesCount}</Badge>
    </span>
  )
}

const JobPage = () => {
  const graphQLClient = useGraphQLClient()
  const location = useLocation()
  const state = location.state as { title?: string }
  const params = useParams()
  const jobId = params?.jobId ?? ''
  const { data, status, refetch } = useQuery({
    queryKey: [GET_JOB_QUERY_KEY, jobId],
    queryFn: async () => graphQLClient.request(GET_JOB_QUERY, { pk: jobId }),
  })

  const [title, setTitle] = useState<string | null>(null)

  const { mutate: submitJob } = useMutation({
    mutationFn: async (variables: SubmitJobMutationVariables) => graphQLClient.request(SUBMIT_JOB_MUTATION, variables),
    onSuccess: () => refetch().then(() => setTitle(null)),
    onError: () => setTitle(null),
  })

  const { job, estimates, estimateNames, jobTitle } = useMemo(() => {
    const job = data?.job ?? null
    const estimates = job?.estimates ?? []
    const jobTitle = job?.title ?? state?.title ?? 'Job Title'
    const estimateNames = estimates.map(estimate => estimate.title)
    return { job, estimates, estimateNames, jobTitle }
  }, [data, state])

  const breadcrumbs = useMemo(() => jobPageBreadcrumbs({ customerId: job?.customerId ?? '', customerName: job?.customer?.name }), [job])

  const rightSideBar = <JobPageRightSidebar job={job as JobType} />
  return (
    <AppPage
      header={title ?? jobTitle}
      actions={<JobPageActions job={job as JobType} />}
      onTitleEdit={onTitleEdit}
      rightSidebarContent={rightSideBar}
      rightSidebarWidth={360}
      breadcrumbs={breadcrumbs}
      disableScroll
    >
      <Routes>
        <Route path='create-estimate' element={<CreateEstimateModal estimateNames={estimateNames} />} />
      </Routes>
      <title>{title ?? jobTitle}</title>
      <TabWrapper>
        <Tabs id='tab-wrapper'>
          <TabList>
            <Tab>Estimates</Tab>
            <Tab>
              Notes
              <NotesBadge notesCount={job?.notesForPrint ? 1 : 0} />
            </Tab>
            <Tab>Media</Tab>
            <Tab>Timeline</Tab>
          </TabList>

          <TabPanel>
            <JobPageTabEstimates estimates={estimates} status={status} />
          </TabPanel>

          <TabPanel>
            <JobPageTabNotes job={job} refetch={refetch} />
          </TabPanel>

          <TabPanel>
            <div>Uploaded files</div>
          </TabPanel>

          <TabPanel>
            <div>History Page</div>
          </TabPanel>
        </Tabs>
      </TabWrapper>
    </AppPage>
  )

  function onTitleEdit(title: string) {
    setTitle(title)
    if (title !== jobTitle && jobTitle !== '') {
      const variables = {
        title,
        jobId,
        status: job?.status ?? '',
        customerId: job?.customerId ?? '',
        projectSiteId: job?.projectSiteId ?? '',
      }
      console.log('onTitleEdit', variables)
      submitJob(variables)
    }
  }
}

export default JobPage

type jobPageBreadcrumbsProps = {
  customerId: string
  customerName?: string
}

const jobPageBreadcrumbs = ({ customerId, customerName }: jobPageBreadcrumbsProps) => {
  const result = []
  if (customerId && customerName) result.push({ navigateTo: `/customers/${customerId}`, label: 'Customer: ' + customerName })

  return result
}

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow: hidden;
  margin-right: 32px;
  div[role='tabpanel'] {
    overflow: auto;
    padding-top: 16px;
  }
`
export const GET_JOB_QUERY_KEY = 'job'

const GET_JOB_QUERY = graphql(/* GraphQL */ `
  query GetJob($pk: ID!) {
    job(pk: $pk) {
      id
      title
      status
      createdAt
      modifiedAt
      projectSiteId
      notesForPrint
      projectSite {
        id
        name
        place {
          id
          displayName
          formattedAddress
          googleMapsUri
          city
          state
          zip
        }
      }
      customerId
      customer {
        id
        name
        workOrderNotes
        businessPhone
        phoneOffice
        phoneMobile
        email
      }
      createdById
      createdBy {
        fullName
        id
      }
      modifiedById
      modifiedBy {
        fullName
        id
      }
      estimates {
        jobId
        id
        workItemCount
        modifiedAt
        title
        startDate
        status
        materialCostTotal
        laborCostTotal
        totalCostTotal
        marginPercent
        totalPriceCalculated
        totalPriceOverride
        totalPriceFinal
        modifiedById
        modifiedBy {
          fullName
          id
        }
        workOrders {
          id
          name
          status
          deferScheduleDate
          isConfirmed
          isWorkCompleted
          statusNotes
          materialCostTotal
          laborCostTotal
          totalCostTotal
          totalPrice
          marginPercent
          nextDispatchDate
          dispatches {
            id
            date
          }
        }
      }
    }
  }
`)
