import { Temporal } from '@js-temporal/polyfill'
import { useMutation } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { ErrorMessage, HelperMessage, Label } from '@atlaskit/form'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import { RadioGroup } from '@atlaskit/radio'
import { type OptionsPropType } from '@atlaskit/radio/types'
import Select from '@atlaskit/select'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'
import type { SelectedType } from '@atlaskit/tabs/dist/types/types'
import Textfield from '@atlaskit/textfield'
import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import { CreateVehicleMutationVariables, SubmitGhostVehicleDateMutationVariables } from '@/gql/graphql'
import useVehiclesQuery from '@/utils/queryHooks/useVehiclesQuery'
import useGraphQLClient from '@/utils/useAuthRequest'

import { VEHICLE_COLOR_OPTIONS } from '../dispatchConstants'

import useDispatchDateQuery from '../DispatchPage/hooks/useDispatchDateQuery'
type FormInputs = {
  name: string
  make: string
  model: string
  year: string
  vin: string
  licensePlate: string
  color: OptionsPropType[0]
  isGhost: string
}

type VehicleCreatorFormProps = {
  closeModal: () => void
}

const options: OptionsPropType = [
  { name: 'isGhost', value: 'No', label: 'Fleet Vehicle' },
  { name: 'isGhost', value: 'Yes', label: 'Ghost Vehicle' },
]

const colorOptions: OptionsPropType = VEHICLE_COLOR_OPTIONS.map(color => ({ name: 'color', value: color, label: color }))

const VehicleCreatorForm = ({ closeModal }: VehicleCreatorFormProps) => {
  const graphQLClient = useGraphQLClient()
  const params = useParams()
  const today = Temporal.PlainDate.from(Temporal.Now.plainDateISO())
  const selectedDate = params.dateString ? Temporal.PlainDate.from(params.dateString) : today
  const dateString = selectedDate.toString()
  const { refetch } = useDispatchDateQuery(dateString)
  const [selected, setSelected] = useState(0)
  const handleUpdate = useCallback((index: SelectedType) => setSelected(index), [setSelected])

  const { vehicles, refetch: refetchVehicles } = useVehiclesQuery()
  const existingVehicleNames = useMemo(() => vehicles.map(vehicle => vehicle.name), [vehicles])
  const ghostVehicles = useMemo(() => vehicles.filter(vehicle => vehicle.isGhost), [vehicles])
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>()

  const handleClose = useCallback(
    (shouldRefetch = false) => {
      closeModal()
      reset()
      if (shouldRefetch) {
        refetch()
        refetchVehicles()
      }
    },
    [closeModal, reset, refetch, refetchVehicles]
  )

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: CreateVehicleMutationVariables) => graphQLClient.request(CREATE_VEHICLE, variables),
    onSuccess: () => handleClose(true),
    onError: error => console.error('Error creating estimate: ', error),
  })

  const { mutate: mutateGhostVehicleDate, isPending: isPendingGhostVehicleDate } = useMutation({
    mutationFn: async (variables: SubmitGhostVehicleDateMutationVariables) => graphQLClient.request(SUBMIT_GHOST_VEHICLE_DATE, variables),
    onSuccess: () => handleClose(true),
    onError: error => console.error('Error submitting ghost vehicle date: ', error),
  })

  const onSubmit: SubmitHandler<FormInputs> = ({ name, make, model, year, vin, licensePlate, color, isGhost }) => {
    const variables: CreateVehicleMutationVariables = {
      name,
      make,
      model,
      year: parseInt(year),
      vin,
      licensePlate,
      color: color?.value ?? '',
      isGhost: isGhost === 'Yes',
    }
    console.log('VehicleCreatorForm onSubmit variables: ', variables)
    mutate(variables)
  }

  const handleSelectGhostVehicle = useCallback(
    (vehicleId: string) => {
      mutateGhostVehicleDate({ vehicleId, date: dateString })
    },
    [mutateGhostVehicleDate, dateString]
  )

  const formIsInvalid = Object.keys(errors).length > 0
  if (formIsInvalid) console.log('VehicleCreatorForm form errors: ', errors)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>Add Vehicle</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <StyledModalContent>
          <Tabs id='tab-wrapper' onChange={handleUpdate} selected={selected}>
            <TabList>
              <Tab>Add Ghost Vehicle</Tab>
              <Tab>Create New Vehicle</Tab>
            </TabList>

            <TabPanel>
              <div className='ghost-vehicle-list-wrapper'>
                <div className='ghost-vehicle-list-header'>Select Ghost Vehicle</div>
                {ghostVehicles.length === 0 ? (
                  <div className='ghost-vehicle-list-empty'>No ghost vehicles found</div>
                ) : (
                  ghostVehicles.map(vehicle => (
                    <Button key={vehicle.id} onClick={() => handleSelectGhostVehicle(vehicle.id)}>
                      {vehicle.name}
                    </Button>
                  ))
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className='vehicle-modal-tab-content'>
                <div className='modal-field-wrapper'>
                  <Controller
                    name='isGhost'
                    control={control}
                    defaultValue={'Yes'}
                    render={({ field }) => <RadioGroup options={options} {...field} />}
                  />
                  <HelperMessage>
                    Fleet vehicles will show on every date. Ghost vehicles are hidden until added manually each day.
                  </HelperMessage>
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: true,
                      minLength: 3,
                      maxLength: 255,
                      validate: value => !existingVehicleNames.includes(value),
                    }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor='basic-textfield'>
                          Unique Vehicle Name
                          <RequiredAsterisk />
                        </Label>
                        <Textfield isInvalid={!!errors?.name} {...field} />

                        {errors?.name?.type === 'minLength' ? <ErrorMessage>Must be at least 3 characters.</ErrorMessage> : null}
                        {errors?.name?.type === 'required' ? <ErrorMessage>Must include a vehicle name.</ErrorMessage> : null}
                        {errors?.name?.type === 'validate' ? <ErrorMessage>A vehicle with this name already exists.</ErrorMessage> : null}
                      </>
                    )}
                  />
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='color'
                    control={control}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <>
                        <Label htmlFor='basic-textfield'>
                          Vehicle Color
                          <RequiredAsterisk />
                        </Label>
                        <Select
                          placeholder='Select Color'
                          menuPosition={'fixed'}
                          options={colorOptions}
                          onChange={onChange}
                          onBlur={onBlur}
                          name={name}
                          value={value}
                          ref={ref}
                        />
                      </>
                    )}
                  />
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='make'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: false,
                      minLength: 2,
                      maxLength: 255,
                    }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor='basic-textfield'>Vehicle Make</Label>
                        <Textfield isInvalid={!!errors?.make} {...field} />

                        {errors?.make?.type === 'minLength' ? <ErrorMessage>Must be at least 2 characters.</ErrorMessage> : null}
                      </>
                    )}
                  />
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='model'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: false,
                      minLength: 2,
                      maxLength: 255,
                    }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor='basic-textfield'>Vehicle Model</Label>
                        <Textfield isInvalid={!!errors?.model} {...field} />

                        {errors?.model?.type === 'minLength' ? <ErrorMessage>Must be at least 2 characters.</ErrorMessage> : null}
                      </>
                    )}
                  />
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='year'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: false,
                      min: 1900,
                      max: new Date().getFullYear() + 1,
                    }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor='basic-textfield'>Vehicle Year</Label>
                        <Textfield isInvalid={!!errors?.year} {...field} />

                        {errors?.year?.type === 'min' ? <ErrorMessage>Must be after 1900.</ErrorMessage> : null}
                        {errors?.year?.type === 'max' ? <ErrorMessage>Must be before {new Date().getFullYear()}.</ErrorMessage> : null}
                      </>
                    )}
                  />
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='vin'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: false,
                      minLength: 17,
                      maxLength: 17,
                    }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor='basic-textfield'>17 Digit VIN</Label>
                        <Textfield isInvalid={!!errors?.vin} {...field} />

                        {errors?.vin?.type === 'minLength' ? <ErrorMessage>Must be 17 characters.</ErrorMessage> : null}
                        {errors?.vin?.type === 'maxLength' ? <ErrorMessage>Must be 17 characters.</ErrorMessage> : null}
                      </>
                    )}
                  />
                </div>

                <div className='modal-field-wrapper'>
                  <Controller
                    name='licensePlate'
                    control={control}
                    defaultValue=''
                    rules={{
                      required: false,
                      minLength: 2,
                      maxLength: 9,
                    }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor='basic-textfield'>License Plate</Label>
                        <Textfield isInvalid={!!errors?.licensePlate} {...field} />

                        {errors?.licensePlate?.type === 'minLength' ? <ErrorMessage>Must be at least 2 characters.</ErrorMessage> : null}
                        {errors?.licensePlate?.type === 'maxLength' ? <ErrorMessage>Must be less than 10 characters.</ErrorMessage> : null}
                      </>
                    )}
                  />
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </StyledModalContent>
      </ModalBody>

      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending}>
          Close
        </Button>
        {selected === 0 ? null : (
          <Button isLoading={isPending || isPendingGhostVehicleDate} appearance='primary' type='submit' isDisabled={formIsInvalid}>
            Create
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default VehicleCreatorForm

const RequiredAsterisk = () => <span style={{ color: token('color.text.danger'), paddingInlineStart: '2px' }}>*</span>

const StyledModalContent = styled.div`
  /* padding-bottom: 60px; */

  .ghost-vehicle-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    /* gap: 8px; */
    > button {
      text-align: left;
      min-width: 60%;
    }

    .ghost-vehicle-list-header {
      font-weight: bold;
      font-size: 16px;
      margin-top: 12px;
      margin-bottom: 8px;
      color: ${token('color.text.subtle')};
    }

    .ghost-vehicle-list-empty {
      font-style: italic;
    }
  }

  .vehicle-modal-tab-content {
    padding: 12px 0 60px 0;
  }

  .modal-field-wrapper {
    margin-bottom: 18px;
  }
`

// const TabWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   align-items: stretch;
//   overflow: hidden;
//   /* margin-right: 32px; */

//   div[role='tabpanel'] {
//     /* overflow: auto; */
//     padding-top: 16px;
//     border: 1px solid red;
//     display: flex;
//     flex-direction: column;
//     flex: 0 0 auto;
//   }
// `

const CREATE_VEHICLE = graphql(/* GraphQL */ `
  mutation CreateVehicle(
    $name: String!
    $make: String
    $model: String
    $year: Int
    $vin: String
    $licensePlate: String
    $color: String
    $isGhost: Boolean!
  ) {
    createVehicle(
      name: $name
      make: $make
      model: $model
      year: $year
      vin: $vin
      licensePlate: $licensePlate
      color: $color
      isGhost: $isGhost
    ) {
      success
      message
      vehicle {
        id
      }
    }
  }
`)

const SUBMIT_GHOST_VEHICLE_DATE = graphql(/* GraphQL */ `
  mutation SubmitGhostVehicleDate($vehicleId: UUID!, $date: Date!, $shouldDelete: Boolean) {
    submitGhostVehicleDate(vehicleId: $vehicleId, date: $date, shouldDelete: $shouldDelete) {
      success
      message
    }
  }
`)
