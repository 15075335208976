import { Route, Routes } from 'react-router'

import { Content } from '@atlaskit/page-layout'

import AppLeftNavigation, { LinkSection } from '@/components/AppLeftNavigation'
import DispatchPayoutDetails from './ReconDispatch/ReconDispatch'
// import InstallerHours from './payroll/InstallerHours'
import ReconDate from './ReconDate/ReconDate'
import ReconHours from './ReconHours/ReconHours'
import WeeklyDispatchPayout from './reports/WeeklyDispatchPayout'
// import InstallerPayouts from './reports/InstallerPayouts'

const RECON_LINKS: LinkSection[] = [
  {
    header: 'Payroll',
    links: [
      {
        label: 'Weekly Dispatch Payout',
        link: '/recon/weekly-payout',
      },
      {
        label: 'Vehicle Recon',
        link: '/recon/vehicle/',
      },
      // {
      //   label: 'Installer Hours',
      //   link: '/recon/installer-hours',
      // },
    ],
  },
  {
    header: 'Reports',
    links: [
      {
        label: 'Weekly Timecards',
        link: '/recon/weekly-timecards',
      },
    ],
  },
]
const ReconRouter = () => {
  return (
    <Content>
      <AppLeftNavigation headerTitle='Recon' headerDescription='Recon' linkSections={RECON_LINKS} />

      <Routes>
        <Route path='weekly-payout/:dateString?' element={<WeeklyDispatchPayout />} />
        <Route path='work-order/:workOrderId/:dateString?' element={<DispatchPayoutDetails />} />
        <Route path='vehicle/:dateString?' element={<ReconDate />} />
        <Route path='weekly-timecards' element={<ReconHours />} />
        {/* <Route path='installer-hours/:dateString?' element={<InstallerHours />} /> */}
        {/* <Route path='installer-payouts' element={<InstallerPayouts />} /> */}
      </Routes>
    </Content>
  )
}

export default ReconRouter
