import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

import ReconAppPage from '../ReconAppPage'

import ReconDatePageActions from './components/ReconDatePageActions'
import VehicleContainer from './components/VehicleContainer'

const ReconDate = () => {
  const params = useParams()
  const dateString = params?.dateString ?? Temporal.Now.plainDateISO().toString()
  const graphQLClient = useGraphQLClient()
  const { data, isPending } = useQuery({
    queryKey: [GET_PAYROLL_DAY_QUERY_KEY, dateString],
    queryFn: async () => await graphQLClient.request(GET_PAYROLL_DAY, { date: dateString }),
  })

  const vehicles = data?.vehiclesByDate ?? []
  const vehiclesWithAssignments = vehicles.filter(vehicle => (vehicle.vehicleDate?.sortedVehicleDispatchIds ?? '').length > 0)
  return (
    <ReconAppPage header={'Vehicle Recon for ' + dateString} isLoading={isPending} actions={<ReconDatePageActions />}>
      <title>Daily Recon {dateString} - Thermal Shop Admin</title>
      <PageContainer>
        {vehiclesWithAssignments.map(vehicle => (
          <VehicleContainer key={vehicle.id} vehicle={vehicle} dateString={dateString} />
        ))}
      </PageContainer>
    </ReconAppPage>
  )
}

export default ReconDate

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const GET_PAYROLL_DAY_QUERY_KEY = 'GetPayrollDay'
const GET_PAYROLL_DAY = graphql(/* GraphQL */ `
  query GetPayrollDay($date: Date!) {
    vehiclesByDate(date: $date) {
      id
      name
      make
      model
      year
      vin
      licensePlate
      color
      isGhost
      vehicleDate {
        id
        isLocked
        sortedVehicleDispatchIds
        totalMilesDriven
        totalMinutesDriven
        totalProximityMinutes
        minutesToArriveHome
        milesToArriveHome
        totalDrivePayout
        vehicleDispatchAssignments {
          id
          vehicleDateId
          proximityPercent
          proximityMinutes
          minutesToArrive
          milesToArrive
          driveHourlyPayRate
          driveHoursPayable
          calculatedDrivePayoutTotal
          calculatedDrivePayoutPerInstaller
          installerCount
          milesAdjusted
          driveMinutesAdjusted
          driveMinutesBuffer
          hoursOnSite
          assignedInstallerIds

          dispatchId
          dispatch {
            id
            date
            totalHoursWorked

            workOrderId
            workOrder {
              id
              name
              tripName
              isConfirmed
              isWorkCompleted
              estimateId
              estimateTitle
              isDavisBacon
              jobId
              jobTitle
              customerName
              projectSiteAddress
            }
          }
        }
      }
    }
  }
`)
