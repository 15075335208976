import { Temporal } from '@js-temporal/polyfill'
import { useParams } from 'react-router'

import { useNavigate } from 'react-router'

import { ButtonGroup } from '@atlaskit/button'
import { IconButton } from '@atlaskit/button/new'

import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'

const ReconDatePageActions = () => {
  const params = useParams()
  const dateString = params?.dateString
  const today = Temporal.Now.plainDateISO()
  const selectedDate = dateString ? Temporal.PlainDate.from(dateString) : today
  const isToday = selectedDate.equals(today)
  const navigate = useNavigate()
  const handleBackOneDay = () => {
    navigate(`/recon/vehicle/${selectedDate.subtract({ days: 1 }).toString()}`)
  }
  const handleForwardOneDay = () => {
    navigate(`/recon/vehicle/${selectedDate.add({ days: 1 }).toString()}`)
  }
  const handleToday = () => {
    navigate(`/recon/vehicle/`)
  }
  return (
    <div>
      <ButtonGroup label='Dispatch Date'>
        <IconButton icon={ChevronLeftLargeIcon} label='Back One Day' isTooltipDisabled={false} onClick={handleBackOneDay} />
        <IconButton icon={RefreshIcon} label='Back to Today' isTooltipDisabled={false} onClick={handleToday} isDisabled={isToday} />
        <IconButton icon={ChevronRightLargeIcon} label='Forward One Day' isTooltipDisabled={false} onClick={handleForwardOneDay} />
      </ButtonGroup>
    </div>
  )
}

export default ReconDatePageActions
