import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'

const getLocalStorageIsDarkMode = () => {
  const isDarkMode = localStorage.getItem('isDarkMode')
  // @ts-expect-error isDarkMode is a string
  const result = isDarkMode === null ? 'system' : isDarkMode === 'true' || isDarkMode === true ? true : false
  console.log('getLocalStorageIsDarkMode', result)
  return result
}
Sentry.init({
  dsn: 'https://ec9ed1346e0c893c86cfc77b2ba0af2b@o4507907596156928.ingest.us.sentry.io/4507907599171584',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
      maskAllText: false,
      blockAllMedia: false,
    }),
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: () => getLocalStorageIsDarkMode(),
    // }),
  ],

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'thermalshop.com'],

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // result in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'unknown',
  enabled: import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'dev',
})

console.log('Sentry initialized')
