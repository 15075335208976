import { useMemo, useState } from 'react'
import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import { GetPayrollDayQuery } from '@/gql/graphql'
import { CardColorOptions, COLOR_OPTIONS } from '@/utils/utilities'

import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import VehicleContainerHeader from './VehicleContainerHeader'
import VehicleDispatchRow, { ROW_WIDTHS } from './VehicleDispatchRow'

import useSubmitVehicleDateStats from '../useSubmitVehicleDateStats'

type Vehicle = GetPayrollDayQuery['vehiclesByDate'][number]
type VehicleDate = NonNullable<Vehicle['vehicleDate']>
type VehicleDispatch = NonNullable<VehicleDate['vehicleDispatchAssignments'][number]>

interface VehicleContainerProps {
  vehicle: Vehicle
  dateString: string
}

const VehicleContainer = ({ vehicle, dateString }: VehicleContainerProps) => {
  // @ts-expect-error - vehicle.color is not always a CardColorOptions
  const color = (COLOR_OPTIONS.includes(vehicle?.color ?? '') ? vehicle.color : 'gray') as CardColorOptions

  const vehicleDispatchIds = vehicle.vehicleDate?.sortedVehicleDispatchIds ?? []

  const vehicleDispatchAssignmentsById = (vehicle.vehicleDate?.vehicleDispatchAssignments ?? []).reduce(
    (acc, vda) => {
      acc[vda.id] = vda
      return acc
    },
    {} as Record<string, VehicleDispatch>
  )

  const vehicleDateId = vehicle.vehicleDate?.id

  const [milesToArrive, setMilesToArrive] = useState(vehicle.vehicleDate?.milesToArriveHome?.toString())
  const [minutesToArrive, setMinutesToArrive] = useState(vehicle.vehicleDate?.minutesToArriveHome?.toString())

  const { mutate, isPending, data } = useSubmitVehicleDateStats(dateString)

  const dbVehicleDate = vehicle.vehicleDate
  const mutationVehicleDate = data?.submitVehicleDateStats?.vehicleDate
  const vehicleDate = useMemo(() => mutationVehicleDate ?? dbVehicleDate, [mutationVehicleDate, dbVehicleDate])

  return (
    <VehicleContainerCard key={vehicle.id} $color={color} $vehicleDispatchCount={vehicleDispatchIds.length} $isLoading={isPending}>
      <VehicleContainerHeader vehicle={vehicle} color={color} />

      <div className='vehicle-body-section'>
        <table>
          <thead>
            <tr>
              <th style={{ width: ROW_WIDTHS[0] }}></th>
              <th style={{ width: ROW_WIDTHS[1] + ROW_WIDTHS[2] }} colSpan={2}>
                Proximity To Shop
              </th>
              <th style={{ width: ROW_WIDTHS[3] + ROW_WIDTHS[4] }} colSpan={2}>
                Travel To Site
              </th>
              <th style={{ width: ROW_WIDTHS[5] }}>On Site</th>
              <th style={{ width: ROW_WIDTHS[6] + ROW_WIDTHS[7] + ROW_WIDTHS[8] + ROW_WIDTHS[9] }} colSpan={4}>
                Payable Calculations
              </th>
              <th style={{ width: ROW_WIDTHS[10] + ROW_WIDTHS[11] + ROW_WIDTHS[12] }} colSpan={3}>
                Payout
              </th>
            </tr>

            <tr>
              <th style={{ width: ROW_WIDTHS[0] }}></th>
              {/* PROXIMITY */}
              <th style={{ width: ROW_WIDTHS[1] }}>Minutes</th>
              <th style={{ width: ROW_WIDTHS[2] }}>Percent</th>
              {/* TRAVEL */}
              <th style={{ width: ROW_WIDTHS[3] }}>Minutes</th>
              <th style={{ width: ROW_WIDTHS[4] }}>Miles</th>
              {/* ON SITE */}
              <th style={{ width: ROW_WIDTHS[5] }}>Hours</th>
              {/* BILLABLE */}
              <th style={{ width: ROW_WIDTHS[6] }}>Miles</th>
              <th style={{ width: ROW_WIDTHS[7] }}>Minutes</th>
              <th style={{ width: ROW_WIDTHS[8] }}>Buffer</th>
              <th style={{ width: ROW_WIDTHS[9] }}>Payable Hr</th>
              {/* DRIVE PAY */}
              <th style={{ width: ROW_WIDTHS[10] }}>Installers</th>
              <th style={{ width: ROW_WIDTHS[11] }}>Drive Rate</th>
              <th style={{ width: ROW_WIDTHS[12] }}>Payout</th>
            </tr>
          </thead>

          <tbody>
            {vehicleDispatchIds.map((vehicleDispatchId, index) => (
              <VehicleDispatchRow
                key={vehicleDispatchId}
                currentIndex={index}
                vehicleDispatch={vehicleDispatchAssignmentsById[vehicleDispatchId]}
                mutate={mutate}
              />
            ))}

            <tr>
              <ReturnJobCell>
                <div className='vehicle-dispatch-card-index'>R</div>

                <div className='job-card-info'>
                  <div className='customer-name'>Return Trip</div>

                  <div className='job-site-address'>
                    <Link appearance='subtle' href={'#'} target='_blank' rel='noopener noreferrer'>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                        <span className='erh-address-link'>From Last Job</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </ReturnJobCell>

              <td style={{ width: ROW_WIDTHS[1], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[2], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[3], padding: 0 }}>
                <TableCellNumberTextField
                  value={minutesToArrive?.toString()}
                  suffix='min'
                  onChange={handleMinutesToArriveChange}
                  onBlur={handleMinutesToArriveSubmit}
                  onKeyDown={handleEnterKeyBlur}
                />
              </td>
              <td style={{ width: ROW_WIDTHS[4], padding: 0 }}>
                <TableCellNumberTextField
                  value={milesToArrive ?? '-'}
                  suffix='mi'
                  onChange={handleMilesToArriveChange}
                  onBlur={handleMilesToArriveSubmit}
                  onKeyDown={handleEnterKeyBlur}
                />
              </td>
              <td style={{ width: ROW_WIDTHS[5], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[6], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[7], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[8], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[9], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[10], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[11], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_WIDTHS[12], backgroundColor: token('color.background.disabled') }} />
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td style={{ width: ROW_WIDTHS[0], paddingLeft: 24, fontSize: 15, fontWeight: 'bold', padding: '8px 8px 8px 25px' }}>
                Vehicle Totals
              </td>
              <td style={{ width: ROW_WIDTHS[1] }}>
                <NumberCell value={vehicleDate?.totalProximityMinutes} suffix='min' readOnly decimalPlaces={0} />
              </td>
              <td style={{ width: ROW_WIDTHS[2] }}></td>
              <td style={{ width: ROW_WIDTHS[3] }}>
                <NumberCell value={vehicleDate?.totalMinutesDriven} suffix='min' readOnly decimalPlaces={0} />
              </td>
              <td style={{ width: ROW_WIDTHS[4] }}>
                <NumberCell value={vehicleDate?.totalMilesDriven} suffix='mi' readOnly />
              </td>
              <td style={{ width: ROW_WIDTHS[5] }} />
              <td style={{ width: ROW_WIDTHS[6] }} />
              <td style={{ width: ROW_WIDTHS[7] }} />
              <td style={{ width: ROW_WIDTHS[8] }} />
              <td style={{ width: ROW_WIDTHS[9] }}>
                <NumberCell
                  value={vehicleDate?.totalMinutesDriven ? Math.max(vehicleDate.totalMinutesDriven - 60, 0) / 60 : 0}
                  suffix='hr'
                  readOnly
                  decimalPlaces={2}
                />
              </td>
              <td style={{ width: ROW_WIDTHS[10] }} />
              <td style={{ width: ROW_WIDTHS[11] }} />

              <td style={{ width: ROW_WIDTHS[12] }}>
                <NumberCell value={vehicleDate?.totalDrivePayout} prefix='$' readOnly />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </VehicleContainerCard>
  )
  function handleMilesToArriveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setMilesToArrive(value)
  }

  function handleMinutesToArriveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setMinutesToArrive(value)
  }

  function handleMilesToArriveSubmit() {
    console.log('handleMilesToArriveSubmit', milesToArrive)
    mutate({ vehicleDateId, attribute: 'milesToArriveHome', value: milesToArrive })
    if (milesToArrive) {
      const roundedValue = parseFloat(milesToArrive).toFixed(2)
      setMilesToArrive(roundedValue)
    }
  }

  function handleMinutesToArriveSubmit() {
    console.log('handleMinutesToArriveSubmit', minutesToArrive)
    mutate({ vehicleDateId, attribute: 'minutesToArriveHome', value: minutesToArrive })
  }

  function handleEnterKeyBlur(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') e.currentTarget.blur()
  }
}

export default VehicleContainer

interface VehicleContainerCardProps {
  $color: CardColorOptions
  $vehicleDispatchCount: number
  $isLoading: boolean
}
const VEHICLE_DISPATCH_CARD_GAP = 8

const VehicleContainerCard = styled.div<VehicleContainerCardProps>`
  border: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
  border-radius: 4px;
  min-width: 500px;
  flex-shrink: 0;
  z-index: 0;
  /* overflow: hidden; */

  .vehicle-body-section {
    position: relative;
    background: ${token('elevation.surface.sunken')};

    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    padding: ${VEHICLE_DISPATCH_CARD_GAP}px ${VEHICLE_DISPATCH_CARD_GAP + 4}px;
    overflow: hidden;
  }

  table {
    opacity: ${({ $isLoading }) => ($isLoading ? 0.5 : 1)};
    transition: opacity 0.2s ease-in-out;
    background: transparent;
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid ${token('color.border')}; */
    td,
    th {
      box-sizing: border-box;
    }
    thead {
      color: ${token('color.text.subtlest')};
      tr {
        border-right: 1px solid ${token('color.border')};
        th + th {
          background: ${token('color.background.neutral')};
          border-top: 1px solid ${token('color.border')};
          border-left: 1px solid ${token('color.border')};
        }

        &:first-child {
          th {
            color: ${token('color.text.disabled')};
            text-align: center;
          }
        }

        &:last-child {
          th {
            text-align: right;
            padding-right: 8px;
          }
        }
      }
    }
    tr {
      border-right: 1px solid ${token('color.border')};
      display: flex;
      align-items: stretch;
      td {
        border-bottom: 1px solid ${token('color.border')};
      }
      td + td {
        border-left: 1px solid ${token('color.border')};
      }
    }

    tbody {
      background: ${token('elevation.surface')};
    }

    tfoot {
      tr {
        /* background: ${token('elevation.surface.sunken')}; */

        td:first-child {
          border-left: 3px solid ${token('color.background.accent.gray.subtle')};
        }

        td + td {
          padding: 0;
          font-size: 15px;
        }
      }
    }
  }
`

const ReturnJobCell = styled.td<{ $accentColor?: CardColorOptions }>`
  height: 100%;
  min-height: 100%;

  padding: 0;
  width: ${ROW_WIDTHS[0]}px;
  min-width: ${ROW_WIDTHS[0]}px;

  border-color: ${token('color.border.accent.gray')};
  border-left: 3px solid ${token('color.border.accent.gray')};
  background: ${token(`color.background.accent.gray.subtlest`)};

  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 0 0 none;

  .vehicle-dispatch-card-index {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    font-size: 18px;
    font-weight: 800;

    flex: 0 0 auto;
    color: ${({ $accentColor }) => (!$accentColor ? token(`color.text.disabled`) : token(`color.text.accent.${$accentColor}`))};
  }

  .job-card-info {
    flex: 1 1 auto;
    padding: 4px 8px 2px 0px;
    overflow: hidden;

    .customer-name {
      padding-bottom: 4px;
      border-bottom: 1px solid ${token('color.border')};
      padding-left: 1px;
      font-weight: bold;
      font-size: 13px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .job-site-address {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      padding: 4px 2px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a > span:not(.erh-address-link):not(.erh-davis-bacon-address) {
        display: none;
      }

      .erh-davis-bacon-address {
        display: inline-block;
        font-weight: 800;
        letter-spacing: -0.08em;
        margin-right: 1px;
        color: ${({ $accentColor }) =>
          // @ts-expect-error known issue with token function
          !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      }

      .erh-address-link {
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a,
      span:not(.erh-davis-bacon-address) {
        color: ${token('color.text')};
        display: inline-block;
      }
    }
  }
`
