import { useMemo } from 'react'
import styled from 'styled-components'

import Textfield from '@atlaskit/textfield'
import { TextfieldProps } from '@atlaskit/textfield/dist/types/types'
import { token } from '@atlaskit/tokens'
import { MONOSPACE_FONT_FAMILY } from '../utils/constants'

type TableCellNumberTextFieldProps = TextfieldProps & {
  prefix?: string | React.ReactNode
  suffix?: string | React.ReactNode
  isDisabled?: boolean | undefined
  width?: number
  mono?: boolean
  showBorder?: boolean
}
const TableCellNumberTextField = ({
  prefix = '',
  suffix = '',
  isDisabled = false,
  mono = true,
  width = 150,
  showBorder = false,
  ...rest
}: TableCellNumberTextFieldProps) => {
  const elemBeforeInput = useMemo(() => {
    if (typeof prefix === 'string') return <PrefixText>{prefix}</PrefixText>
    return prefix
  }, [prefix])

  const elemAfterInput = useMemo(() => {
    if (typeof suffix === 'string') {
      if (suffix === '') return <div style={{ width: 1 }} />

      return <SuffixText>{suffix}</SuffixText>
    }
    return suffix
  }, [suffix])

  return (
    <FieldWrapper $mono={mono}>
      <Textfield
        appearance={!showBorder ? 'subtle' : undefined}
        isDisabled={isDisabled}
        elemBeforeInput={elemBeforeInput}
        elemAfterInput={elemAfterInput}
        width={width}
        type='number'
        placeholder='-'
        {...rest}
      />
    </FieldWrapper>
  )
}

export default TableCellNumberTextField
const PrefixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.subtlest')};
  margin-left: 8px;
`
const SuffixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.disabled')};
  margin-right: 8px;
`
const FieldWrapper = styled.div<{ $mono: boolean }>`
  /* .custom-input {
    height: 31.333px;
  } */

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > div > span {
    flex: 0 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input {
    text-align: right !important;
    appearance: none;
    -moz-appearance: textfield;
    font-family: ${({ $mono }) => ($mono ? MONOSPACE_FONT_FAMILY : 'inherit')};
    /* height: 30px; */
  }
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
