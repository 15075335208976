import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { graphql } from '@/gql'
import { GetDispatchPayoutDetailsQuery, SubmitDispatchPayoutRecalcMutationVariables } from '@/gql/graphql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useGraphQLClient from '@/utils/useAuthRequest'

import NumberCell from '@/components/NumberCell'
import InstallerRow from './InstallerRow'

type WorkOrder = GetDispatchPayoutDetailsQuery['workOrder']
type Dispatch = WorkOrder['dispatches'][number]

interface InstallerTableProps {
  dispatch: Dispatch
}
const CELL_PAD = '4px 8px'

const InstallerTable = ({ dispatch }: InstallerTableProps) => {
  const installerAssignments = dispatch.installerAssignments.sort((a, b) => {
    // First sort by vehicle name
    const vehicleCompare = (a.vehicleName ?? '').localeCompare(b.vehicleName ?? '')
    if (vehicleCompare !== 0) return vehicleCompare

    // Then sort by installer name
    return a.installer.fullName.localeCompare(b.installer.fullName)
  })

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: SubmitDispatchPayoutRecalcMutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_DISPATCH_PAYOUT_RECALC, variables)
      if (!response.submitDispatchPayoutRecalc?.success)
        throw new Error(response.submitDispatchPayoutRecalc?.message ?? 'Failed to recalculate payout')
      return response.submitDispatchPayoutRecalc
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
    },
  })

  const recalcEnabled = dispatch.percentPieceRateAllocated !== '100.00'

  return (
    <StyledTable>
      <thead>
        <tr>
          <th colSpan={3} />

          <th colSpan={3}>Hourly</th>

          <th colSpan={3}>Drive Pay</th>

          <th colSpan={3}>Piece Rate</th>

          <th colSpan={2}>Totals</th>
        </tr>
        <tr>
          <th>Vehicle</th>
          <th>Installer</th>
          <th>P/Hr</th>
          {/* HOURLY */}
          <th>On Site</th>
          <th>Pay Rate</th>
          <th>Payout</th>
          {/* DRIVE PAY */}
          <th>Payable</th>
          <th>Pay Rate</th>
          <th>Payout</th>
          {/* PIECE RATE */}
          <th>% of Work</th>
          <th>Addons Payout</th>
          <th>PR Payout</th>
          {/* TOTAL */}
          <th>Total Payout</th>
          <th>Locked</th>
        </tr>
      </thead>

      <tbody>
        {installerAssignments.map((assignment, index) => {
          const isLastOfVehicle =
            index === installerAssignments.length - 1 || assignment.vehicleName !== installerAssignments[index + 1].vehicleName
          const isFirstOfVehicle = index === 0 || assignment.vehicleName !== installerAssignments[index - 1].vehicleName
          return (
            <InstallerRow
              key={assignment.id}
              installerDispatch={assignment}
              isLastOfVehicle={isLastOfVehicle}
              isFirstOfVehicle={isFirstOfVehicle}
            />
          )
        })}

        {/* <tr>
          <td>Total Entered</td>

          <td>
            <NumberCell
              padding={CELL_PAD}
              readOnly
              suffix='hrs'
              value={installerAssignments.reduce(
                (acc, a) => acc + Number(installerAmounts[a.installerId]?.hoursWorked ?? a.hoursWorked),
                0
              )}
            />
          </td>

          <td>
            <NumberCell padding={CELL_PAD} readOnly value={totalInstallerPayout} prefix='$' />
          </td>

          <td colSpan={2}>
            <Button appearance='subtle' onClick={handleAutoCalculate} isDisabled={!totalTargetPayout} shouldFitContainer>
              Auto Calculate
            </Button>
          </td>
        </tr> */}

        {/* {totalTargetPayout !== totalInstallerPayout && (
          <tr style={{ background: token('color.background.danger') }}>
            <td>Remaining</td>

            <td></td>

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={totalTargetPayout - totalInstallerPayout} prefix='$' />
            </td>

            <td colSpan={2}></td>
          </tr>
        )} */}
      </tbody>

      <tfoot>
        <tr>
          <td></td>
          <td>Total Entered</td>
          <td style={{ background: token('color.background.disabled') }}></td>
          <td>
            <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalHoursWorked} suffix='hrs' monoPrefixSuffix />
          </td>
          <td style={{ background: token('color.background.disabled') }}></td>
          <td>
            <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidHourly} prefix='$' monoPrefixSuffix />
          </td>
          <td style={{ background: token('color.background.disabled') }}></td>
          <td style={{ background: token('color.background.disabled') }}></td>
          <td>
            <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidDrivePay} prefix='$' monoPrefixSuffix />
          </td>
          <td>
            <div className='recalc-button-overlay'>
              <Button
                appearance='subtle'
                onClick={() => mutate({ dispatchId: dispatch.id })}
                isDisabled={isPending}
                isLoading={isPending}
                shouldFitContainer
              >
                Recalc
              </Button>
            </div>
            <NumberCell
              padding={CELL_PAD}
              readOnly
              value={dispatch.percentPieceRateAllocated}
              suffix='%'
              appearance={recalcEnabled ? 'warning' : 'default'}
            />
          </td>
          <td>
            <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidAddons} prefix='$' monoPrefixSuffix />
          </td>
          <td>
            <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidPieceRate} prefix='$' monoPrefixSuffix />
          </td>
          <td>
            <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidLaborTotal} prefix='$' monoPrefixSuffix />
          </td>
          <td style={{ background: token('color.background.disabled') }}></td>
        </tr>
      </tfoot>
    </StyledTable>
  )

  // function handleAutoCalculate() {
  //   console.log('handleAutoCalculate')
  //   const lockedAmount = Object.keys(installerAmounts).reduce(
  //     (acc, id) =>
  //       acc +
  //       (installerAmounts[id]?.locked
  //         ? Number(installerAmounts[id]?.payoutAmount ?? installerAssignments.find(a => a.installerId === id)?.payoutAmount ?? 0)
  //         : 0),
  //     0
  //   )
  //   const unlockedAmount = totalTargetPayout - lockedAmount
  //   const unlockedInstallerAssignments = installerAssignments.filter(assignment => !installerAmounts[assignment.installerId]?.locked)
  //   const unlockedAmountPerInstaller = unlockedAmount / unlockedInstallerAssignments.length
  //   console.log({ lockedAmount, unlockedAmount, unlockedAmountPerInstaller, unlockedInstallerAssignments, installerAmounts })
  //   unlockedInstallerAssignments.forEach(assignment => {
  //     const installerId = assignment.installerId as string
  //     console.log(`Setting ${assignment.installer.fullName} with ID ${installerId} to ${unlockedAmountPerInstaller}`)
  //     setInstallerAmounts(prev => ({
  //       ...prev,
  //       [installerId]: { ...(prev?.[installerId] ?? {}), payoutAmount: unlockedAmountPerInstaller.toFixed(2) },
  //     }))
  //   })
  // }

  // function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
  //   setTimeout(() => {
  //     e.target.select()
  //   }, 1) // Delay until the next event loop cycle
  // }
}

export default InstallerTable

const StyledTable = styled.table`
  width: fit-content;

  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  thead {
    color: ${token('color.text.subtlest')};
    tr {
      border-right: 1px solid ${token('color.border')};

      &:first-child {
        th {
          text-align: center;
        }
        th + th {
          background: ${token('color.background.neutral')};
          border-top: 1px solid ${token('color.border')};
          border-left: 1px solid ${token('color.border')};
        }
      }
      &:last-child {
        th {
          background: ${token('color.background.neutral')};
          text-align: right;
          padding-right: 8px;
          border-top: 1px solid ${token('color.border')};
          border-left: 1px solid ${token('color.border')};

          &:nth-child(1) {
            text-align: left;
            padding-left: 8px;
            padding-right: 8px;
          }
          &:nth-child(2) {
            text-align: left;
          }
        }
      }
    }
  }

  tbody {
    > tr {
      border-right: 1px solid ${token('color.border')};
      border-left: 1px solid ${token('color.border')};

      &:nth-child(even) {
        /* background-color: ${token('color.background.neutral')}; */
        background-color: ${token('elevation.surface.sunken')};

        > td > div > div {
          &:not(:focus-within) {
            background: transparent;
          }
        }
      }

      > td + td {
        border-left: 1px solid ${token('color.border')};
      }
      > td {
        padding: 0;
        font-family: ${MONOSPACE_FONT_FAMILY};
        height: 32px;
        text-align: right;
        border-bottom: 1px solid ${token('color.border')};

        &:nth-child(1) {
          /* Vehicle */
          text-align: left;
          padding-left: 8px;
          padding-right: 8px;
          font-family: ${token('font.family.body')};
          font-weight: 500;
        }
        &:nth-child(2) {
          /* Installer */
          text-align: left;
          font-family: ${token('font.family.body')};
          font-weight: 500;

          padding-left: 8px;
          padding-right: 8px;
        }
        &:nth-child(4) {
          /* On Site */
          width: 100px;
          min-width: 100px;
        }
        &:nth-child(5) {
          /* Hourly Pay Rate */
          width: 110px;
          min-width: 110px;
        }
        &:nth-child(6) {
          /* Payout */
          min-width: 80px;
        }
        &:nth-child(7) {
          /* Drive Payable */
          width: 100px;
          min-width: 100px;
        }
        &:nth-child(8) {
          /* Drive Pay Rate */
          width: 110px;
          min-width: 110px;
        }
        &:nth-child(9) {
          /* Drive Payout */
          min-width: 80px;
        }
        &:nth-child(10) {
          /* Percent of Work */
          width: 95px;
          min-width: 95px;
        }
        &:nth-child(11) {
          /* Addons Payout */
          min-width: 80px;
        }
        &:nth-child(12) {
          /* Piece Rate Payout */
          min-width: 80px;
        }
        &:nth-child(13) {
          /* Total Payout */
          min-width: 100px;
          font-weight: 600;
        }

        > div > div {
          max-width: 120px;
          display: flex;
          justify-content: flex-end;
          border-radius: 0;
          /* border-color: ${token('color.border')}; */

          > span {
            color: ${token('color.text.disabled')};
          }

          input {
            /* max-width: 120px; */
            font-family: ${MONOSPACE_FONT_FAMILY};
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            -moz-appearance: textfield;
            appearance: textfield;
          }
        }

        > label {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  tfoot {
    tr {
      border-right: 1px solid ${token('color.border')};
      border-left: 1px solid ${token('color.border')};
      td {
        height: 32px;
        text-align: right;
        font-family: ${MONOSPACE_FONT_FAMILY};
        padding: 0;
        font-weight: 600;
        position: relative;

        &:nth-child(2) {
          /* Installer */
          text-align: left;
          font-family: ${token('font.family.body')};
          font-weight: 500;

          padding-left: 8px;
          padding-right: 8px;
          border-left: 1px solid transparent;
        }

        .recalc-button-overlay {
          position: absolute;
          inset: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          background: ${token('color.background.warning')};
          opacity: 0;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    td + td {
      border-left: 1px solid ${token('color.border')};
    }
  }

  .row-header {
    font-family: ${token('font.family.body')};
    font-weight: 500;
    padding: 0 8px;
  }
  .checkbox-wrapper {
    padding: 0 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    > label {
      transform-origin: center;
      transform: scale(1.33);
    }
  }
`
const SUBMIT_DISPATCH_PAYOUT_RECALC = graphql(/* GraphQL */ `
  mutation SubmitDispatchPayoutRecalc($dispatchId: ID!) {
    submitDispatchPayoutRecalc(dispatchId: $dispatchId) {
      success
      message
    }
  }
`)
