import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { LeftSidebar } from '@atlaskit/page-layout'
import {
  ButtonItem,
  GoBackItem,
  Header,
  NavigationContent,
  NavigationFooter,
  NavigationHeader,
  Section,
  SideNavigation,
} from '@atlaskit/side-navigation'
import { token } from '@atlaskit/tokens'

import { VERSION_NUMBER } from '../utils/constants'

export type LinkSection = {
  header: string
  links: LinkItem[]
}
type AppLeftNavigationProps = {
  backNavigateTo?: string
  backLabel?: string
  headerTitle: string
  headerDescription: string
  linkSections: LinkSection[]
}

type LinkItem = {
  label: string
  link: string
  isSelected?: (pathname: string) => boolean
}

const AppLeftNavigation = ({
  backNavigateTo,
  backLabel,
  headerTitle = 'headerTitle',
  headerDescription = 'headerDescription',
  linkSections,
}: AppLeftNavigationProps) => {
  const navigate = useNavigate()

  const location = useLocation()

  const backComponent = useMemo(() => {
    if (!backNavigateTo) return null
    return (
      <Section>
        <GoBackItem onClick={() => navigate(backNavigateTo)}>Back to {backLabel}</GoBackItem>
      </Section>
    )
  }, [backLabel, backNavigateTo, navigate])

  // need to ignore types for backComponent
  return (
    <LeftSidebar shouldPersistWidth id='space-navigation' skipLinkTitle='Space Navigation' isFixed={false}>
      <SideNavigation label='SideNav'>
        <NavigationHeader>
          <Header description={headerDescription}>{headerTitle}</Header>
        </NavigationHeader>

        {backComponent}

        <NavigationContent>
          {linkSections.map((section, ind) => (
            <Section title={section?.header} key={ind} hasSeparator>
              {section.links.map(linkItem => (
                <ButtonItem
                  key={linkItem.link}
                  isSelected={linkItem?.isSelected ? linkItem.isSelected(location?.pathname) : location?.pathname === linkItem.link}
                  onClick={() => navigate(linkItem.link)}
                >
                  {linkItem.label}
                </ButtonItem>
              ))}
            </Section>
          ))}
        </NavigationContent>

        <NavigationFooter>
          <VersionNumber>
            <div>v{VERSION_NUMBER}</div>
          </VersionNumber>
        </NavigationFooter>
      </SideNavigation>
    </LeftSidebar>
  )
}

export default AppLeftNavigation

const VersionNumber = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  justify-content: center;
  color: ${token('color.text.disabled')};
  /* border: 1px solid red; */
`
