import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { CELL_PADDING_HORIZONTAL, CELL_PADDING_VERTICAL } from '@/pages/jobs/EstimateEditor/editorConstants'
import { FONT_FAMILY, MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { formatNumber } from '@/utils/utilities'

interface NumberCellProps {
  value: number | string | null | undefined
  decimalPlaces?: number
  prefix?: string
  suffix?: string
  readOnly?: boolean
  padding?: string
  monoPrefixSuffix?: boolean
  appearance?: 'default' | 'warning' | 'danger'
}

const NumberCell = ({
  value,
  decimalPlaces = 2,
  prefix = '',
  suffix = '',
  readOnly = false,
  padding,
  monoPrefixSuffix = false,
  appearance = 'default',
}: NumberCellProps) => {
  const { valueToUse, shouldDim } = useMemo(() => {
    let valueToUse = value
    if (value === null || value === undefined || value === '') valueToUse = '-'
    else if (typeof value === 'string') valueToUse = parseFloat(value).toFixed(decimalPlaces)
    else if (typeof value === 'number') valueToUse = value.toFixed(decimalPlaces)
    else valueToUse = 'INVALID'
    if (valueToUse === '-1400.00') console.log('valueToUse', valueToUse)
    let shouldDim = false
    if (valueToUse === '-') shouldDim = true
    else if (typeof valueToUse === 'string' && valueToUse !== 'INVALID' && valueToUse.includes('0')) {
      const parts = valueToUse.split('.')
      const leftOfDecimal = parts[0]
      const rightOfDecimal = parts[1]
      if (parseInt(leftOfDecimal) === 0 && parseInt(rightOfDecimal) === 0) shouldDim = true
    }

    if (typeof valueToUse === 'number' && (valueToUse >= 1000 || valueToUse <= -1000)) valueToUse = formatNumber(valueToUse, decimalPlaces)
    else if (
      typeof valueToUse === 'string' &&
      !isNaN(parseFloat(valueToUse)) &&
      (parseFloat(valueToUse) >= 1000 || parseFloat(valueToUse) <= -1000)
    )
      valueToUse = formatNumber(parseFloat(valueToUse), decimalPlaces)

    return { valueToUse, shouldDim }
  }, [value, decimalPlaces])

  return (
    <StyledNumberCell style={{ padding }} $readOnly={readOnly} $shouldDim={shouldDim} $appearance={appearance}>
      {!prefix ? <span /> : <span className={`num-cell-prefix ${monoPrefixSuffix ? 'mono' : ''}`}>{prefix}</span>}

      <span className='num-cell-value-suffix'>
        <span className='num-cell-value'>{valueToUse}</span>

        {!!suffix && <span className={`num-cell-suffix ${monoPrefixSuffix ? 'mono' : ''}`}>{suffix}</span>}
      </span>
    </StyledNumberCell>
  )
}

export default NumberCell

const StyledNumberCell = styled.div<{ $readOnly: boolean; $shouldDim: boolean; $appearance: 'default' | 'warning' | 'danger' }>`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: ${MONOSPACE_FONT_FAMILY};
  opacity: ${({ $readOnly }) => ($readOnly ? 0.9 : 1)};
  padding: ${`${CELL_PADDING_VERTICAL}px ${CELL_PADDING_HORIZONTAL}px`};
  white-space: nowrap;

  ${({ $appearance }) => $appearance === 'warning' && `background: ${token('color.background.warning')};`}
  ${({ $appearance }) => $appearance === 'danger' && `background: ${token('color.background.danger')};`}

  .num-cell-prefix {
    margin-right: 4px;
    ${({ $readOnly }) => ($readOnly ? undefined : 'padding-left: 6px')};
    color: ${token('color.text.disabled')};
    white-space: nowrap;
    flex: 0 0 auto;
    &.mono {
      font-family: ${MONOSPACE_FONT_FAMILY};
    }
  }

  .num-cell-value-suffix {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .num-cell-suffix {
      padding-left: 6px;
      font-weight: 500;
      color: ${token('color.text.disabled')};
      padding-right: ${({ $readOnly }) => ($readOnly ? undefined : '6px')};
      font-family: ${FONT_FAMILY};
      white-space: nowrap;
      /* overflow: hidden;
      text-overflow: ellipsis; */
      flex: 0 0 auto;
      &.mono {
        font-family: ${MONOSPACE_FONT_FAMILY};
      }
    }

    .num-cell-value {
      flex: 1 1 auto;
      color: ${({ $shouldDim, $appearance }) =>
        $shouldDim
          ? token('color.text.disabled')
          : $appearance === 'danger'
            ? token('color.text.danger')
            : $appearance === 'warning'
              ? token('color.text.warning')
              : 'inherit'};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
