import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import type { TableProps } from 'antd'
import { ConfigProvider, Table } from 'antd'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'
import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'
import { formatCurrencyString } from '@/utils/utilities'

import ReconAppPage from '../ReconAppPage'

import DispatchPayoutTopTable from './components/DispatchPayoutTopTable'
import InstallersTable from './components/InstallersTable'

const ReconDispatch = () => {
  const graphQLClient = useGraphQLClient()
  const theme = useAntdTableTheme()
  const params = useParams()
  const workOrderId = params.workOrderId
  const dateString = params.dateString ?? Temporal.Now.plainDateISO().toString()

  const { data, isPending } = useQuery({
    queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId],
    queryFn: async () => {
      if (!workOrderId) throw new Error('Work Order ID is required')
      const response = await graphQLClient.request(GET_DISPATCH_PAYOUT_DETAILS, {
        workOrderId,
      })
      if (!response.workOrder) throw new Error('Work Order not found')
      return response
    },
    enabled: !!workOrderId,
  })

  const workOrder = data?.workOrder
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))

  const breadcrumbs = useMemo(() => {
    return [{ navigateTo: '/recon/weekly-payout', label: 'Weekly Payout' }]
  }, [])
  const title = `${workOrder?.id}-${workOrder?.name}`

  const selectedDispatch = dispatches.find(dispatch => dispatch.date === dateString)

  const workItems = workOrder?.workItems ?? []

  const header = `Dispatch Recon - WO #${title}`

  return (
    <ReconAppPage header={header} breadcrumbs={breadcrumbs} isRightPanelOpen={true} isLoading={isPending} rightPanelWidth={8}>
      <title>{header}</title>

      <StyledPageWrapper>
        <DispatchPayoutTopTable workOrder={workOrder} dateString={dateString} />

        <h4 style={{ paddingBottom: 0 }}>Installer Assignments</h4>
        {selectedDispatch && <InstallersTable dispatch={selectedDispatch} />}

        <h4 style={{ paddingBottom: 8 }}>Work Order Items</h4>
        <ConfigProvider theme={theme}>
          <Table<WorkItemType>
            size='small'
            dataSource={workItems}
            columns={workOrderItemsColumns}
            rowKey='id'
            rowClassName={(item, index) => {
              let className = index % 2 === 1 ? 'alt-row-style' : 'main-row-style'
              if (item.materialName.toLowerCase().includes('total')) className = className + ' total-row-style'
              return className
            }}
            pagination={false}
          />
        </ConfigProvider>
      </StyledPageWrapper>
    </ReconAppPage>
  )
}

export default ReconDispatch

type WorkItemType = NonNullable<GetDispatchPayoutDetailsQuery['workOrder']>['workItems'][number]

const workOrderItemsColumns: TableProps<WorkItemType>['columns'] = [
  {
    key: 'workAreaName',
    dataIndex: 'workAreaName',
    title: 'Work Area',
  },
  {
    key: 'materialName',
    dataIndex: 'materialName',
    title: 'Material',
  },

  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: 'Qty',
    align: 'right',
    className: 'right mono',
  },
  {
    key: 'unitOfMeasure',
    dataIndex: 'unitOfMeasure',
    title: 'Unit',
  },
  {
    key: 'needed',
    dataIndex: 'containersNeededFinal',
    title: 'Needed',
    align: 'right',
    className: 'right mono',
  },
  {
    key: 'containerLabel',
    dataIndex: 'containerLabel',
    title: 'Cont',
  },
  {
    key: 'laborCost',
    dataIndex: 'laborCostFinal',
    title: 'Labor Cost',
    align: 'right',
    className: 'right mono',

    render: text => formatCurrencyString(text),
  },
]

const StyledPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;

  .alt-row-style {
    background-color: ${token('color.background.neutral')};
  }

  .mono {
    font-family: ${MONOSPACE_FONT_FAMILY};
  }

  .ant-table {
    font-size: 13px !important;
    margin-bottom: 48px !important;
  }
`
const RightPanelWrapper = styled.div`
  border-left: 1px solid ${token('color.border')};
  padding: 24px 32px 16px 24px;
  min-height: 100%;
`

const GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY = 'GetDispatchPayoutDetails'
const GET_DISPATCH_PAYOUT_DETAILS = graphql(/* GraphQL */ `
  query GetDispatchPayoutDetails($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      tripName
      tripId
      isDavisBacon

      totalHoursWorked

      totalPaidAddons
      totalPaidPieceRate
      totalPaidLaborTotal
      totalPaidDrivePay

      totalEnteredPieceRate
      totalEnteredDrivePay
      totalEnteredAddons

      remainingAddons
      remainingDrivePay
      remainingPieceRate
      remainingLaborTotal

      projectedAddons
      projectedDrivePay
      projectedPieceRate
      projectedLaborTotal

      estimateTitle
      jobId
      jobTitle
      projectSiteAddress
      customerName
      marginPercent

      dispatches {
        id
        date

        totalHoursWorked
        percentPieceRateAllocated

        enteredPieceRate
        enteredAddons
        enteredDrivePay
        enteredPayTotal

        totalPaidHourly
        totalPaidAddons
        totalPaidDrivePay
        totalPaidPieceRate
        totalPaidLaborTotal

        workOrderId

        payoutAddons {
          id
          reason
          payoutAmount
          note
        }

        installerAssignments {
          id
          isHourly

          payoutDrive
          payoutHourly

          payoutAddons
          payoutPieceRate

          payoutTotal

          hoursWorked
          hourlyRate
          hoursOnSite

          driveHoursPayable
          driveHourlyPayRate

          percentPieceRate
          isPercentLocked

          vehicleName
          vehicleColor

          installerId
          installer {
            isHourly
            hourlyRate
            fullName
          }
        }
      }
      workItems {
        id
        quantity
        containersNeededFinal
        isVisibleOnWorkOrder
        workAreaName
        tripName
        materialName
        itemCode
        containerLabel
        containersNeededFinal
        unitOfMeasure
        laborCostFinal
      }
    }
  }
`)
