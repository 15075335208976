import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { ButtonGroup } from '@atlaskit/button'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'

import { graphql } from '@/gql'
import useGraphQLClient from '@/utils/useAuthRequest'

import AppPage from '@/components/AppPage'
import PageTabBadge from '@/components/PageTabBadge'
import CreateJobModal from '@/pages/jobs/JobsList/CreateJobModal'

import CustomerPageJobsTab from './CustomerPageJobsTab'
import CustomerPageTabNotes from './CustomerPageTabNotes'

type PreselectedCustomerType = { label: string; value: string }

const ButtonsSet = ({ preselectedCustomer }: { preselectedCustomer: PreselectedCustomerType }) => {
  return (
    <ButtonGroup label='Buttons'>
      <CreateJobModal preselectedCustomer={preselectedCustomer} />
      <DropdownMenu trigger='Add'>
        <DropdownItemGroup>
          <DropdownItem>Contact</DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
    </ButtonGroup>
  )
}

const CustomerPage = () => {
  const params = useParams()
  const customerId = params?.customerId
  const { state } = useLocation()

  const graphQLClient = useGraphQLClient()
  const { data, status, refetch } = useQuery({
    queryKey: ['GetCustomer', { id: customerId ?? '' }],
    queryFn: async () => graphQLClient.request(GET_CUSTOMER_QUERY, { id: customerId ?? '' }),
    enabled: !!customerId,
  })
  const customer = data?.customer
  const customerName = customer?.name ?? state?.name ?? ''
  const breadcrumbs = useMemo(() => customerPageBreadcrumbs({ customerName, customerId: customerId ?? '' }), [customerName, customerId])

  const preselectedCustomer = useMemo(
    () => ({ label: customerName, value: customerId }) as PreselectedCustomerType,
    [customerName, customerId]
  )

  return (
    <AppPage
      header={customerName}
      actions={<ButtonsSet preselectedCustomer={preselectedCustomer} />}
      rightSidebarWidth={360}
      breadcrumbs={breadcrumbs}
    >
      <title>{customerName}</title>
      <TabWrapper>
        <Tabs id='tab-wrapper'>
          <TabList>
            <Tab>Jobs</Tab>
            {/* <Tab>Contacts</Tab> */}
            <Tab>
              Builder Notes
              <PageTabBadge count={customer?.workOrderNotes ? 1 : 0} />
            </Tab>
          </TabList>

          <TabPanel>
            <CustomerPageJobsTab status={status} jobs={customer?.jobs ?? []} />
          </TabPanel>

          {/* <TabPanel>
            <div>Is this tab necessary? Useful if we want to separate people from the customer business.</div>
          </TabPanel> */}

          <TabPanel>
            <CustomerPageTabNotes customer={customer ?? null} refetch={refetch} />
          </TabPanel>
        </Tabs>
      </TabWrapper>
    </AppPage>
  )
}

export default CustomerPage

type customerPageBreadcrumbsProps = {
  customerName: string
  customerId: string
}
const customerPageBreadcrumbs = ({ customerName, customerId }: customerPageBreadcrumbsProps) => [
  { navigateTo: '/customers', label: 'Customers' },
  {
    navigateTo: `/customers/${customerId}`,
    label: customerName,
  },
]

const TabWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  flex: 1;
`

const GET_CUSTOMER_QUERY = graphql(/* GraphQL */ `
  query GetCustomer($id: ID!) {
    customer(pk: $id) {
      id
      businessName
      businessPhone
      workOrderNotes
      email
      isBusiness
      name
      createdAt

      createdBy {
        id
        fullName
      }

      jobs {
        id
        title
        status
        createdAt
      }

      contacts {
        id
        contactType
        emailPrimary
        emailSecondary
        firstName
        lastName
        phoneMobile
        phoneOffice
      }

      locations {
        id
        placeId
        place {
          displayName
          city
          state
          streetNumber
          websiteUri
          shortFormattedAddress
          googleMapsUri
          formattedAddress
          formattedPhoneNumber
          zip
        }
      }
    }
  }
`)
