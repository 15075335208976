import styled from 'styled-components'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'
import { toTitleCase } from '@/utils/utilities'
import { IconButton } from '@atlaskit/button/new'
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down'
import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import NumberCell from '@/components/NumberCell'
import { useState } from 'react'
import AddOnModal from '../AddOnModal/AddOnModal'
import DispatchPieceRateTextField from './DispatchPieceRateTextField'

const CELL_PAD = '4px 8px'

const getNegativeStyle = (value: number) =>
  !value || value > 0 ? {} : { color: token('color.text.danger'), fontWeight: '600', backgroundColor: token('color.background.danger') }

interface DispatchPayoutTopTableProps {
  workOrder?: NonNullable<GetDispatchPayoutDetailsQuery['workOrder']>
  dateString: string
}

const DispatchPayoutTopTable = ({ workOrder, dateString }: DispatchPayoutTopTableProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))

  const selectedDispatch = dispatches.find(dispatch => dispatch.date === dateString)
  const dispatchAddons = selectedDispatch?.enteredAddons ?? 0

  const projectedDrivePay = workOrder?.projectedDrivePay ?? 0
  const projectedPieceRate = workOrder?.projectedPieceRate ?? 0
  const projectedAddons = workOrder?.projectedAddons ?? 0
  const projectedTotal = workOrder?.projectedLaborTotal ?? 0

  const totalEnteredPieceRate = workOrder?.totalEnteredPieceRate ?? 0
  const totalEnteredDrivePay = workOrder?.totalEnteredDrivePay ?? 0
  const totalEnteredAddons = workOrder?.totalEnteredAddons ?? 0
  const totalEnteredLaborTotal = (Number(totalEnteredDrivePay) + Number(totalEnteredPieceRate) + Number(totalEnteredAddons)).toFixed(2)

  const remainingDrivePay = Number(projectedDrivePay) - Number(totalEnteredDrivePay)
  const remainingPieceRate = Number(projectedPieceRate) - Number(totalEnteredPieceRate)
  const remainingAddons = Number(projectedAddons) - Number(totalEnteredAddons)
  const remainingLaborTotal = Number(projectedTotal) - Number(totalEnteredLaborTotal)

  const enteredPieceRate = selectedDispatch?.enteredPieceRate
  const multiDispatch = dispatches.length > 1
  const thisDispatchIndex = dispatches.findIndex(dispatch => dispatch.date === dateString) + 1
  return (
    <TopTableWrapper>
      <table>
        <thead>
          <tr>
            <th />
            <th>Drive Pay</th>
            <th>Piece Rate</th>
            <th>Addons</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Projected</td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={projectedDrivePay} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={projectedPieceRate} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={projectedAddons} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={projectedTotal} prefix='$' />
            </td>
          </tr>

          <tr>
            <td style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 2 }}>Paid Out</span>
              <div
                className='paid-out-reveal-button'
                style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)', transition: 'transform 0.25s ease-in-out' }}
              >
                {multiDispatch && (
                  <IconButton
                    icon={iconProps => <ChevronDownIcon {...iconProps} color={token('color.icon.subtlest')} />}
                    label='Show All Dispatches'
                    onClick={() => setIsOpen(prev => !prev)}
                    appearance='subtle'
                    isTooltipDisabled={isOpen}
                    shape='circle'
                  />
                )}
              </div>
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={totalEnteredDrivePay} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={totalEnteredPieceRate} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={totalEnteredAddons} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={totalEnteredLaborTotal} prefix='$' />
            </td>
          </tr>

          {!isOpen
            ? null
            : dispatches.map((dispatch, ind) => {
                const isSelected = dispatch.date === dateString
                const backgroundColor = isSelected ? token('color.background.selected') : 'transparent'
                const borderBottomWidth = ind === dispatches.length - 1 ? 3 : 1
                const borderBottom = `${borderBottomWidth}px solid ${token('color.border')}`
                const style = { backgroundColor, fontSize: 12, borderBottom }
                return (
                  <tr key={dispatch.date} style={style}>
                    <td style={{ paddingLeft: 8, color: isSelected ? token('color.text.selected') : token('color.text.subtlest') }}>
                      {dispatch.date}
                    </td>
                    <td>
                      <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredDrivePay} prefix='$' />
                    </td>
                    <td>
                      <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredPieceRate} prefix='$' />
                    </td>
                    <td>
                      <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredAddons} prefix='$' />
                    </td>
                    <td>
                      <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredPayTotal} prefix='$' />
                    </td>
                  </tr>
                )
              })}

          <tr>
            <td>Remaining</td>
            <td style={getNegativeStyle(remainingDrivePay)}>
              <NumberCell padding={CELL_PAD} readOnly value={remainingDrivePay} prefix='$' />
            </td>
            <td style={getNegativeStyle(remainingPieceRate)}>
              <NumberCell padding={CELL_PAD} readOnly value={remainingPieceRate} prefix='$' />
            </td>
            <td style={getNegativeStyle(remainingAddons)}>
              <NumberCell padding={CELL_PAD} readOnly value={remainingAddons} prefix='$' />
            </td>
            <td style={getNegativeStyle(remainingLaborTotal)}>
              <NumberCell padding={CELL_PAD} readOnly value={remainingLaborTotal} prefix='$' />
            </td>
          </tr>

          <tr style={{ backgroundColor: token('color.background.neutral'), minHeight: 16 }}>
            <td colSpan={5} style={{ padding: '4px 0px', fontWeight: 700, borderTop: `2px solid ${token('color.border')}` }}>
              This Dispatch{' '}
              <span style={{ fontWeight: 400 }}>
                ({thisDispatchIndex} of {dispatches.length})
              </span>
            </td>
          </tr>

          <tr>
            <td>Entered Piece Rate</td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.enteredDrivePay} prefix='$' />
            </td>
            <td style={{ padding: 0 }}>
              <DispatchPieceRateTextField databaseValue={enteredPieceRate} dispatchId={selectedDispatch?.id} />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={dispatchAddons} prefix='$' />
            </td>
            <td style={{ backgroundColor: token('color.background.neutral') }}>
              <AddOnModal dispatchId={selectedDispatch?.id} />
            </td>
          </tr>

          {selectedDispatch?.payoutAddons.map(addon => (
            <tr key={addon.id} style={{ fontSize: 13 }}>
              <td> + Addon: {toTitleCase(addon.reason ?? 'None')}</td>
              <td colSpan={2} className='addon-note'>
                <Tooltip content={addon.note}>
                  <span>{addon.note}</span>
                </Tooltip>
              </td>
              <td>
                <NumberCell padding={CELL_PAD} readOnly value={addon.payoutAmount} prefix='$' />
              </td>
              <td className='addon-control-cell' style={{ backgroundColor: token('color.background.neutral'), textAlign: 'center' }}>
                <AddOnModal dispatchId={selectedDispatch?.id} selectedAddon={addon} />
              </td>
            </tr>
          ))}
        </tbody>

        {/* <CreateAddonRow dispatchId={selectedDispatch?.id} /> */}
        <tfoot>
          <tr>
            <td>Actual Payout</td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidDrivePay} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidPieceRate} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidAddons} prefix='$' />
            </td>
            <td>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidTotal} prefix='$' />
            </td>
          </tr>
        </tfoot>
      </table>
    </TopTableWrapper>
  )
}

const TopTableWrapper = styled.div`
  > table {
    width: fit-content;
    transition: opacity 0.2s ease-in-out;
    background: transparent;
    border-collapse: collapse;

    td,
    th {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    thead {
      color: ${token('color.text.subtlest')};
      tr {
        border-right: 1px solid ${token('color.border')};
        th + th {
          background: ${token('color.background.neutral')};
          border-top: 1px solid ${token('color.border')};
          border-left: 1px solid ${token('color.border')};
        }

        &:last-child {
          th {
            text-align: right;
            padding-right: 8px;
          }
        }
      }
    }
    tr {
      border-right: 1px solid ${token('color.border')};

      td,
      th {
        &:nth-child(1) {
          width: 140px;
          padding-right: 8px;
          color: ${token('color.text.subtlest')};
          font-weight: 500;
        }
        &:nth-child(2) {
          width: 110px;
        }
        &:nth-child(3) {
          width: 110px;
        }
        &:nth-child(4) {
          width: 100px;
        }
        &:nth-child(5) {
          width: 120px;
        }
      }

      td {
        border-bottom: 1px solid ${token('color.border')};
        padding: 0;

        &.addon-note {
          padding: 0 8px;
          color: ${token('color.text.subtlest')};
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.addon-control-cell {
          opacity: 0.4;
          transition: opacity 0.12s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
      td + td {
        border-left: 1px solid ${token('color.border')};
      }
    }

    tfoot {
      tr {
        td {
          font-weight: 500;
          color: ${token('color.text')};
          &:first-child {
            color: ${token('color.text')};
            font-weight: 600;
          }
        }
        td + td {
          font-size: 15px;
          padding: 0;
        }
      }
    }
  }
`

export default DispatchPayoutTopTable
