import { OptionPropType } from '@atlaskit/radio/dist/types/types'

export const VERSION_NUMBER: string = '2024.12.13.0'
export const MONOSPACE_FONT_FAMILY: string = `"IBM Plex Mono", SFMono-Medium, "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Consolas, Courier, monospace`
export const FONT_FAMILY: string = `apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif`

export const unitOfMeasureOptions: OptionPropType[] = [
  { name: 'unitOfMeasure', value: 'each', label: 'each' },
  { name: 'unitOfMeasure', value: 'sqft', label: 'sqft' },
  { name: 'unitOfMeasure', value: 'linft', label: 'linft' },
]
export const containerLabelOptions: OptionPropType[] = [
  { name: 'containerLabel', value: 'item', label: 'item' },
  { name: 'containerLabel', value: 'bag', label: 'bag' },
]

export const GRAPHQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_ENDPOINT

export const TABLE_LOADING_STATUS_CONVERSION = {
  error: 'error',
  success: 'idle',
  pending: 'loading',
}

export const MONTH_NAMES_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const DAYS_OF_WEEK_ABBR_LIST = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
