import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'
import { GetPayrollDayQuery, SubmitVehicleDateStatsMutationVariables } from '@/gql/graphql'
import { useState } from 'react'

type VehicleDate = NonNullable<GetPayrollDayQuery['vehiclesByDate'][number]['vehicleDate']>
type VehicleDispatch = NonNullable<VehicleDate['vehicleDispatchAssignments'][number]>
interface VehicleDispatchRowProps {
  vehicleDispatch: VehicleDispatch
  currentIndex: number
  mutate: (variables: SubmitVehicleDateStatsMutationVariables) => void
}

export const ROW_WIDTHS = [200, 80, 100, 84, 100, 90, 90, 80, 80, 100, 85, 110, 100]

const VehicleDispatchRow = ({ currentIndex, vehicleDispatch, mutate }: VehicleDispatchRowProps) => {
  // const assignedInstallerIds = vehicleDispatch?.assignedInstallerIds ?? []
  const workOrder = vehicleDispatch?.dispatch?.workOrder ?? null
  const tripName = workOrder?.tripName ?? 'No Trip Name'
  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'
  const address = workOrder?.projectSiteAddress ?? 'No Job Site Address'
  const isDavisBacon = workOrder?.isDavisBacon ?? false

  const [milesToArrive, setMilesToArrive] = useState(vehicleDispatch?.milesToArrive?.toString())
  const [minutesToArrive, setMinutesToArrive] = useState(vehicleDispatch?.minutesToArrive?.toString())
  const [proximityMinutes, setProximityMinutes] = useState(vehicleDispatch?.proximityMinutes?.toString())
  const [hoursOnSite, setHoursOnSite] = useState(vehicleDispatch?.hoursOnSite?.toString())
  const [driveHourlyPayRate, setDriveHourlyPayRate] = useState(vehicleDispatch?.driveHourlyPayRate?.toString())

  // const proximityPercent = vehicleDispatch?.proximityPercent
  const driveMinutesAdjusted = vehicleDispatch?.driveMinutesAdjusted
  const driveMinutesBuffer = vehicleDispatch?.driveMinutesBuffer
  const milesAdjusted = vehicleDispatch?.milesAdjusted
  const driveHoursPayable = vehicleDispatch?.driveHoursPayable

  return (
    <JobRow>
      <JobCell $accentColor={accentColor}>
        <div className='vehicle-dispatch-card-index'>{currentIndex + 1}</div>

        <div className='job-card-info'>
          <div className='customer-name'>{workOrder?.customerName ?? 'No Customer Name'}</div>

          <div className='job-site-address'>
            <Link
              appearance='subtle'
              href={`/jobs/${workOrder?.jobId}/estimates/${workOrder?.estimateId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                {isDavisBacon ? <span className='erh-davis-bacon-address'>DB&nbsp;</span> : null}
                <span className='erh-address-link'>{address}</span>
              </div>
            </Link>
          </div>
        </div>
      </JobCell>

      <td style={{ width: ROW_WIDTHS[1] }}>
        <TableCellNumberTextField
          value={proximityMinutes ?? '-'}
          suffix='min'
          onChange={handleProximityMinutesChange}
          onBlur={handleProximityMinutesBlur}
          onKeyDown={handleEnterKeyBlur}
        />
      </td>
      <td style={{ width: ROW_WIDTHS[2], background: token('color.background.disabled') }}>
        <NumberCell value={vehicleDispatch?.proximityPercent} suffix='%' readOnly />
      </td>
      <td style={{ width: ROW_WIDTHS[3] }}>
        <TableCellNumberTextField
          value={minutesToArrive?.toString() ?? '-'}
          suffix='min'
          onChange={handleMinutesToArriveChange}
          onBlur={handleMinutesToArriveBlur}
          onKeyDown={handleEnterKeyBlur}
        />
      </td>
      <td style={{ width: ROW_WIDTHS[4] }}>
        <TableCellNumberTextField
          value={milesToArrive?.toString() ?? '-'}
          suffix='mi'
          onChange={handleMilesToArriveChange}
          onBlur={handleMilesToArriveBlur}
          onKeyDown={handleEnterKeyBlur}
        />
      </td>
      <td style={{ width: ROW_WIDTHS[5] }}>
        <TableCellNumberTextField
          value={hoursOnSite?.toString() ?? '-'}
          suffix='hr'
          onChange={handleHoursOnSiteChange}
          onBlur={handleHoursOnSiteBlur}
          onKeyDown={handleEnterKeyBlur}
        />
      </td>
      <td style={{ width: ROW_WIDTHS[6], background: token('color.background.disabled') }}>
        <NumberCell value={milesAdjusted} suffix='mi' readOnly />
      </td>
      <td style={{ width: ROW_WIDTHS[7], background: token('color.background.disabled') }}>
        <NumberCell value={driveMinutesAdjusted} suffix='min' readOnly decimalPlaces={0} />
      </td>
      <td style={{ width: ROW_WIDTHS[8], background: token('color.background.disabled') }}>
        <NumberCell value={driveMinutesBuffer} suffix='min' readOnly decimalPlaces={0} />
      </td>
      <td style={{ width: ROW_WIDTHS[9], background: token('color.background.disabled') }}>
        <NumberCell value={driveHoursPayable} suffix='hr' readOnly decimalPlaces={2} />
      </td>
      <td style={{ width: ROW_WIDTHS[10], background: token('color.background.disabled') }}>
        <NumberCell value={vehicleDispatch?.installerCount} readOnly decimalPlaces={0} suffix='crew' />
      </td>
      <td style={{ width: ROW_WIDTHS[11] }}>
        <TableCellNumberTextField
          value={driveHourlyPayRate?.toString() ?? '-'}
          suffix='/ hr'
          prefix='$'
          onChange={handleDriveHourlyPayRateChange}
          onBlur={handleDriveHourlyPayRateBlur}
          onKeyDown={handleEnterKeyBlur}
        />
      </td>
      <td style={{ width: ROW_WIDTHS[12], background: token('color.background.disabled') }}>
        <NumberCell value={vehicleDispatch?.calculatedDrivePayoutTotal} prefix='$' readOnly decimalPlaces={2} />
      </td>
    </JobRow>
  )

  function handleMilesToArriveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setMilesToArrive(value)
  }

  function handleMinutesToArriveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setMinutesToArrive(value)
  }

  function handleProximityMinutesChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setProximityMinutes(value)
  }

  function handleHoursOnSiteChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setHoursOnSite(value)
  }

  function handleDriveHourlyPayRateChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setDriveHourlyPayRate(value)
  }

  function handleMilesToArriveBlur() {
    let compareValue = milesToArrive
    if (milesToArrive) {
      compareValue = parseFloat(milesToArrive).toFixed(2)
      setMilesToArrive(compareValue)
    }
    if (compareValue !== String(vehicleDispatch?.milesToArrive)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'milesToArrive',
        value: compareValue,
      })
    }
  }

  function handleMinutesToArriveBlur() {
    const value = minutesToArrive ? Math.round(parseFloat(minutesToArrive)).toString() : minutesToArrive
    setMinutesToArrive(value)
    if (String(value) !== String(vehicleDispatch?.minutesToArrive)) {
      console.log('mutating minutesToArrive: ', { value: String(value), db: String(vehicleDispatch?.minutesToArrive) })
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'minutesToArrive',
        value,
      })
    }
  }

  function handleProximityMinutesBlur() {
    const value = proximityMinutes ? Math.round(parseFloat(proximityMinutes)).toString() : proximityMinutes
    setProximityMinutes(value)
    if (String(value) !== String(vehicleDispatch?.proximityMinutes)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'proximityMinutes',
        value,
      })
    }
  }

  function handleHoursOnSiteBlur() {
    let compareValue = hoursOnSite
    if (hoursOnSite) {
      compareValue = parseFloat(hoursOnSite).toFixed(2)
      setHoursOnSite(compareValue)
    }
    if (compareValue !== String(vehicleDispatch?.hoursOnSite)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'hoursOnSite',
        value: compareValue,
      })
    }
  }

  function handleDriveHourlyPayRateBlur() {
    let compareValue = driveHourlyPayRate
    if (driveHourlyPayRate) {
      compareValue = parseFloat(driveHourlyPayRate).toFixed(2)
      setDriveHourlyPayRate(compareValue)
    }
    if (compareValue !== String(vehicleDispatch?.driveHourlyPayRate)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'driveHourlyPayRate',
        value: compareValue,
      })
    }
  }

  function handleEnterKeyBlur(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') e.currentTarget.blur()
  }
}

export default VehicleDispatchRow

const JobRow = styled.tr`
  background-color: ${token('elevation.surface')};

  td + td {
    padding: 0;
    vertical-align: middle;
    text-align: right;
    position: relative;
  }
`

const JobCell = styled.td<{ $accentColor: CardColorOptions }>`
  height: 100%;
  min-height: 100%;

  padding: 0;
  width: ${ROW_WIDTHS[0]}px;
  min-width: ${ROW_WIDTHS[0]}px;

  border-color: ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  border-bottom-width: 5px;
  border-left: 3px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  background: ${({ $accentColor }) => token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtle`)};

  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 0 0 none;

  .vehicle-dispatch-card-index {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    font-size: 18px;
    font-weight: 800;

    flex: 0 0 auto;
    color: ${({ $accentColor }) =>
      // @ts-expect-error known issue with token function
      !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
  }

  .job-card-info {
    flex: 1 1 auto;
    padding: 4px 8px 2px 0px;
    overflow: hidden;

    .customer-name {
      padding-bottom: 4px;
      border-bottom: 1px solid ${token('color.border')};
      padding-left: 1px;
      font-weight: bold;
      font-size: 13px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .job-site-address {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      padding: 4px 2px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a > span:not(.erh-address-link):not(.erh-davis-bacon-address) {
        display: none;
      }

      .erh-davis-bacon-address {
        display: inline-block;
        font-weight: 800;
        letter-spacing: -0.08em;
        margin-right: 1px;
        color: ${({ $accentColor }) =>
          // @ts-expect-error known issue with token function
          !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      }

      .erh-address-link {
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a,
      span:not(.erh-davis-bacon-address) {
        color: ${token('color.text')};
        display: inline-block;
      }
    }
  }
`
