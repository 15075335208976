import { useQuery } from '@tanstack/react-query'
import type { TableColumnsType } from 'antd'
import { ConfigProvider, Table } from 'antd'
import { useParams } from 'react-router'
import styled from 'styled-components'

import logo from '@/assets/Logo96x96.png'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { GetPrintableLoadSheetQuery } from '@/gql/graphql'

const LoadSheetPrint = () => {
  const params = useParams()
  const dateString = params?.dateString ?? ''
  const vehicleId = params?.vehicleId ?? ''

  const graphqlClient = useGraphQLClient()
  const theme = useAntdTableTheme(true)

  const { data, status } = useQuery({
    queryKey: [GET_PRINTABLE_LOAD_SHEET_QUERY_KEY, dateString, vehicleId],
    queryFn: async () => graphqlClient.request(GET_PRINTABLE_LOAD_SHEET, { date: dateString, vehicleId, truckId: vehicleId }),
    enabled: !!dateString && !!vehicleId,
  })

  const loadSheetItems = data?.calculatedLoadSheetItems ?? []
  const vehicle = data?.vehicle ?? null
  const vehicleName = vehicle?.name ?? '-'
  const dispatchIds = vehicle?.sortedVehicleDispatchIds ?? []
  const vehicleDispatches = dispatchIds.map(id => data?.vehicleDispatchAssignments?.find(assign => assign.id === id))

  const jobInstallerIds = vehicleDispatches.map(dispatch =>
    (dispatch?.installerAssignments ?? []).map(assign => assign.installerId).sort((a, b) => a.localeCompare(b))
  )
  const allJobsSameInstallers = jobInstallerIds.every(arr => JSON.stringify(arr) === JSON.stringify(jobInstallerIds[0]))
  return (
    <ConfigProvider theme={theme}>
      <title>
        Load Sheet for {vehicleName} - {dateString}
      </title>

      <PageWrapper>
        <PrintableWrapper>
          <HeaderRow>
            <ThermalShopInfo>
              <img src={logo} alt='Thermal Shop Logo' />
              <WorkOrderTitleText>
                {vehicleName} - {dateString}
              </WorkOrderTitleText>
            </ThermalShopInfo>

            <PrintInfo>
              <AttributeColumn>
                {dateString === null ? null : <Attribute>Dispatch Date</Attribute>}
                {vehicleName === '' ? null : (
                  <>
                    <Attribute>Vehicle</Attribute>
                    <Attribute>Make</Attribute>
                    <Attribute>Model</Attribute>
                    <Attribute>Color</Attribute>
                  </>
                )}
              </AttributeColumn>

              <ValuesColumn>
                {dateString === null ? null : <Value>{dateString}</Value>}
                {vehicleName === '' ? null : (
                  <>
                    <Value>{vehicleName}</Value>
                    <Value>{vehicle?.make ?? '-'}</Value>
                    <Value>{vehicle?.model ?? '-'}</Value>
                    <Value>{vehicle?.color ?? '-'}</Value>
                  </>
                )}
              </ValuesColumn>
            </PrintInfo>
          </HeaderRow>

          {!allJobsSameInstallers || vehicleDispatches.length === 0 ? null : (
            <div className='installers-section'>
              <b>Installers:</b>{' '}
              {(vehicleDispatches[0]?.installerAssignments ?? [])
                .sort((a, b) => a.installer?.fullName.localeCompare(b.installer?.fullName))
                .map(assignment => assignment.installer?.fullName)
                .join(', ')}
            </div>
          )}

          <JobsWrapper>
            {vehicleDispatches.length === 0
              ? 'No Dispatches'
              : vehicleDispatches.map((vehicleDispatch, ind) => {
                  const workOrder = vehicleDispatch?.dispatch?.workOrder
                  const installerNames = (vehicleDispatch?.installerAssignments ?? [])
                    .sort((a, b) => a.installer?.fullName.localeCompare(b.installer?.fullName))
                    .map(assignment => assignment.installer?.fullName)
                    .join(', ')
                  return (
                    <JobInfoBlock key={vehicleDispatch?.id}>
                      <div className='dispatch-work-order-index'>
                        Job {ind + 1}: {workOrder?.id}-{workOrder?.name}{' '}
                      </div>
                      {allJobsSameInstallers ? null : <div className='dispatch-work-order-attribute'>{installerNames}</div>}
                      <div className='dispatch-work-order-attribute'>{workOrder?.customerName}</div>
                      <div className='dispatch-work-order-attribute'>{workOrder?.projectSiteAddress}</div>
                    </JobInfoBlock>
                  )
                })}
          </JobsWrapper>

          <Table<LoadSheetItem>
            columns={loadSheetColumns}
            dataSource={loadSheetItems}
            size='small'
            loading={status === 'pending'}
            pagination={false}
            rowClassName={(item, index) => {
              let className = index % 2 === 1 ? 'alt-row-style' : 'main-row-style'
              if (item.materialName.toLowerCase().includes('total')) className = className + ' total-row-style'
              return className
            }}
          />
        </PrintableWrapper>
      </PageWrapper>
    </ConfigProvider>
  )
}

export default LoadSheetPrint
export type LoadSheetItem = GetPrintableLoadSheetQuery['calculatedLoadSheetItems'][number]
const loadSheetColumns: TableColumnsType<LoadSheetItem> = [
  {
    title: 'Material',
    dataIndex: 'materialName',
  },
  {
    title: 'SKU',
    dataIndex: 'itemCode',
  },
  {
    title: 'Quantity',
    dataIndex: 'unitQuantity',
    align: 'right',
    render: (value: number) => <span style={{ fontFamily: MONOSPACE_FONT_FAMILY }}>{value}</span>,
  },
  {
    title: 'UoM',
    dataIndex: 'materialUnitOfMeasure',
  },
  {
    title: 'Called For',
    dataIndex: 'quantityCalledFor',
    align: 'right',
    render: (value: number) => <span style={{ fontFamily: MONOSPACE_FONT_FAMILY }}>{value}</span>,
  },
  {
    title: 'Container',
    dataIndex: 'materialContainerLabel',
    className: 'border-column',
  },
  {
    title: 'Loaded',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
  {
    title: 'Used',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
  {
    title: 'Returned',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
  {
    title: 'Actual',
    width: 80,
    align: 'right',
    className: 'border-column',
  },
]

const GET_PRINTABLE_LOAD_SHEET_QUERY_KEY = 'GetPrintableLoadSheet'
const GET_PRINTABLE_LOAD_SHEET = graphql(/* GraphQL */ `
  query GetPrintableLoadSheet($date: Date!, $vehicleId: UUID!, $truckId: ID!) {
    calculatedLoadSheetItems(date: $date, vehicleId: $vehicleId) {
      materialName
      materialContainerLabel
      materialUnitOfMeasure
      itemCode
      quantityCalledFor
      unitQuantity
    }
    vehicle(pk: $truckId) {
      id
      sortedVehicleDispatchIds
      name
      licensePlate
      color
      make
      model
    }
    vehicleDispatchAssignments(filters: { dispatchDate: $date, vehicleId: $vehicleId }) {
      id
      vehicleId

      installerAssignments {
        id

        installerId
        installer {
          fullName
        }
      }

      dispatchId
      dispatch {
        id
        date
        workOrderId
        workOrder {
          id
          name
          isConfirmed
          isWorkCompleted
          tripName
          jobId
          jobTitle
          projectSiteAddress
          customerName
          estimateId
          estimateTitle
          isDavisBacon
        }
      }
    }
  }
`)

const JobInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  min-width: 160px;
  /* padding: 8px 0; */

  .dispatch-work-order-attribute {
    font-size: 11px;
    font-weight: 400;
    margin: 0;
  }
  .dispatch-work-order-index {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
  }
`
const JobsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  padding: 8px;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 12px;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px;
`
const ThermalShopInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  > img {
    height: 72px;
    width: 72px;
    margin-right: 8px;
  }
  .mono {
    font-family: ${MONOSPACE_FONT_FAMILY};
  }
`
const WorkOrderTitleText = styled.h2`
  font-weight: 400;
  margin: 0;
  color: #000;
`
const PrintInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 12px;
`
const AttributeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: 12px;
`
const Attribute = styled.div`
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const ValuesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const Value = styled.div`
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  color: #000;
  background-color: #fff;
  overflow: auto;
`
const PrintableWrapper = styled.div`
  flex: 1;
  /* border: 3px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  padding: 24px;
  max-width: 740px;
  min-width: 740px;

  background-color: #fff;

  .installers-section {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    padding: 6px 8px;
  }

  .ant-table-cell {
    color: #000;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    white-space: nowrap;
    /* border: 1px solid red; */
  }
  .alt-row-style {
    background-color: #f0f0f0;
  }
  .main-row-style {
    background-color: #fff;
  }
  .total-row-style {
    font-weight: 700;
  }
  td.border-column {
    border-right: 1px solid ${token('color.border')};
  }
`
