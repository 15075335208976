import { graphql } from '@/gql'
import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import { createColumnHelper, flexRender, getCoreRowModel, GroupColumnDef, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { GetWeeklyTimecardsQuery } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import NumberCell from '@/components/NumberCell'

import ReconAppPage from '../ReconAppPage'
import EditHoursCell from './components/EditHoursCell'

type WeeklyTimecard = GetWeeklyTimecardsQuery['weeklyTimecards'][number]

const ReconHours = () => {
  const [lastWeek, setLastWeek] = useState(() => {
    const today = Temporal.Now.plainDateISO()
    const dayOfWeek = today.dayOfWeek
    return today.subtract({ days: dayOfWeek - 1 }) // Adjust to Monday (1 is Monday in Temporal)
  })
  const [firstWeek, setFirstWeek] = useState(() => lastWeek.subtract({ weeks: 4 }))

  const weekOfLastWeek = lastWeek.weekOfYear
  const weekOfFirstWeek = firstWeek.weekOfYear
  const differenceInWeeks = weekOfLastWeek - weekOfFirstWeek
  const weeks = Array.from({ length: differenceInWeeks }, (_, i) => firstWeek.add({ weeks: i }))

  const graphqlClient = useGraphQLClient()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['reconHours', firstWeek.toString(), lastWeek.toString()],
    queryFn: () => {
      return graphqlClient.request(WEEKLY_TIMECARDS, {
        lastWeek: lastWeek.toString(),
        firstWeek: firstWeek.toString(),
        staffId: null,
      })
    },
  })

  console.log({ data, weekOfLastWeek, weekOfFirstWeek })

  // Transform data for table

  const columns = useMemo(() => getColumnDefs(weeks), [weeks])
  const tableData = data?.weeklyTimecards ?? ([] as WeeklyTimecard[])
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <ReconAppPage header='Recon Hours'>
      <TableWrapper>
        {isLoading && <div>Loading...</div>}
        {isError && <div>Error loading data</div>}

        <Table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </ReconAppPage>
  )
}

export default ReconHours

function getColumnDefs(weeks: Temporal.PlainDate[]) {
  const columnHelper = createColumnHelper<WeeklyTimecard>()

  const cols = [
    columnHelper.group({
      header: ' ',
      columns: [
        columnHelper.accessor(row => row.staff.lastName + ', ' + row.staff.firstName, {
          header: 'Staff Name',
          cell: info => info.getValue(),
        }),
      ],
    }),
  ]

  weeks.forEach(weekOf => {
    const weekString = weekOf.toString()
    const weekLabel = weekOf.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    })

    const group = columnHelper.group({
      header: weekLabel,
      columns: [
        columnHelper.accessor(row => row.weeks.find(week => week.weekOf === weekString)?.timecard?.hoursWorked ?? null, {
          id: weekString,
          header: 'Hours',
          cell: ({ row, cell }) => <EditHoursCell value={cell.getValue()} staffId={row.original.staff.id} weekOf={weekString} />,
        }),
        columnHelper.accessor(
          row => {
            const correctWeek = row.weeks.find(week => week.weekOf === weekString)
            return correctWeek?.dispatchCount ?? 0
          },
          {
            id: weekString + 'DispatchCount',
            header: 'Disp',
            cell: info => <NumberCell value={info.getValue()} decimalPlaces={0} readOnly />,
          }
        ),
      ],
    }) as GroupColumnDef<WeeklyTimecard, unknown>
    cols.push(group)
  })

  return cols
}

const TableWrapper = styled.div`
  overflow-x: auto;
  padding-bottom: 16px;
  padding-right: 16px;
  margin-right: -16px;
`

const Table = styled.table`
  width: fit-content;
  border-collapse: separate;
  border-spacing: 0;
  background: transparent;
  border-bottom: 3px solid ${token('color.border')};

  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr {
    border-left: 1px solid ${token('color.border')};
    border-right: 1px solid ${token('color.border')};
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    tr:first-child {
      th + th {
        text-align: center;
      }
    }
    tr {
      background: ${token('elevation.surface.raised')};

      th + th {
        border-left: 1px solid ${token('color.border')};
      }
      /* Add bottom border to last row of header */
      ${props => (props.theme.dir === 'rtl' ? '&:first-child' : '&:last-child')} {
        /* box-sizing: border-box; */
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          border-bottom: 2px solid ${token('color.border')};
        }
      }
    }
  }
  tbody {
    z-index: 1;
    tr {
      td + td {
        border-left: 1px solid ${token('color.border')};
      }

      td:nth-child(even) {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
      }
    }
  }
`

const Th = styled.th`
  padding: 4px 8px;
  text-align: left;
  position: relative;
  background: ${token('elevation.surface.raised')}; /* Ensure background covers gaps */

  &:first-child {
    padding-left: 8px;
  }
  background: ${token('color.background.neutral')};

  &:last-child {
    padding-right: 8px;
  }
`

const Td = styled.td`
  padding: 0;
  border-bottom: 1px solid ${token('color.border')};
  background: ${token('elevation.surface')}; /* Add background to prevent see-through */

  &:first-child {
    padding-left: 8px;
    padding-right: 8px;
  }
`

const WEEKLY_TIMECARDS = graphql(/* GraphQL */ `
  query GetWeeklyTimecards($lastWeek: Date, $firstWeek: Date, $staffId: ID) {
    weeklyTimecards(lastWeek: $lastWeek, firstWeek: $firstWeek, staffId: $staffId) {
      staff {
        id
        fullName
        firstName
        lastName
        isDriver
        isHourly
      }
      weeks {
        timecard {
          id
          weekOf
          hoursWorked
          hoursWorkedOvertime
          payoutTotal
        }
        weekOf
        dispatchHours
        dispatchCount
      }
    }
  }
`)
