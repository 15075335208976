import { useState } from 'react'

import TableCellNumberTextField from '@/components/TableCellNumberTextField'

interface EditHoursCellProps {
  value: string
  staffId: string
  weekOf: string
}
const EditHoursCell = ({ value, staffId, weekOf }: EditHoursCellProps) => {
  const [hours, setHours] = useState<string | null>(value)

  return <TableCellNumberTextField value={hours ?? value ?? ''} onChange={handleChange} onBlur={handleBlur} suffix='hr' />

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const newValue = event.currentTarget.value
    setHours(newValue)
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const textValue = event.currentTarget.value
    const formattedValue = !textValue ? null : Number(textValue).toFixed(2)
    setHours(formattedValue ?? '')
    console.log('blur')
  }
}

export default EditHoursCell
